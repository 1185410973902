import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Image, Modal, Nav, Row, Tab,} from 'react-bootstrap'; // Assuming you are using Bootstrap for styling
import {Link, useLocation, useParams} from 'react-router-dom';
import {Icon} from '@iconify/react';
import ChatScreenHeader from './ChatScreenHeader';
import ChatScreenFooter from './ChatScreenFooter';
import FCompanionsInfoLessSetting from './FCompanionsInfoLessSetting';
import PrimaryBtn from '../../Components/CommonBtn/PrimaryBtn';
import {toast} from 'react-hot-toast';
import {SSE} from 'sse';
import parse from 'html-react-parser';
import axios from 'axios';
import 'react-circular-progressbar/dist/styles.css';
import classNames from 'classnames';
import './ChatScreenPage.css';
import {t} from 'i18next';
import {Trans} from 'react-i18next';
import PopupModal from '../../Components/PopUps/PopUpBase';
import InviteFriendPopup from '../../Components/PopUps/InviteFriendPopup/InviteFriendPopup';
import SmoothImage from 'react-smooth-image';
import tone from '../././../../public/images/bip-sound.mp3';
import {
  ClassifyVoice,
  containsImageRequest,
  convertMessagesForWait,
  convertMessagesForWaitAppGenerator,
  convertMessagesForWaitCodeGenerator,
  convertMessagesImageGenerationEverytime,
  convertMessagesForImageGeneration,
  convertMessagesShort,
  convertRAGMessages,
  extractListItems,
  formatTime,
  generateCodeOnly,
  GenerateLongDescription,
  SummarizeDescription,
  uploadImageToS3,
  useChatScroll,
} from './utils';
import {AudioVideoQueue, ChatMessage, ImageFileWithPreview,} from './interface';
import {AppState, GeneratedCodeConfig, Settings,} from '../../Components/AppGenerator/hooks/types';
import {AssetData} from '../../Components/AppGenerator/helpers/interface';
import {History,} from '../../Components/AppGenerator/history/history_types';
import HistoryDisplay from '../../Components/AppGenerator/history/HistoryDisplay';
import {usePersistedState} from '../../Components/AppGenerator/hooks/usePersistedState';
import {USER_CLOSE_WEB_SOCKET_CODE} from '../../Components/AppGenerator/helpers/config';
import {CodeGenerationParams,} from '../../Components/AppGenerator/helpers/generateCode';
import Preview from '../../Components/AppGenerator/Preview';
import CodeTab from '../../Components/AppGenerator/CodeTab';
import {takeScreenshot} from '../../Components/AppGenerator/helpers/IdeaToAppFunctions';
import {extractHistoryTree} from '../../Components/AppGenerator/history/utils';
import {
  ChatbotData,
  ConversationData,
  GetConversations,
  GetFeaturedChatbot,
  GetUserChatbotById,
  HasUserTalkedWithChatbot,
  StatsData,
  GetFeaturedChatbotByName,
  UpdateUserChatbot,
} from '../../Utils/ChatbotUtils';
import {v4 as uuidv4} from 'uuid';

import {RegisterOrboxUse, RUNPOD_BASE_URL, RUNPOD_ROMANIAN_SERVER,} from '../../Utils/Utils';
import {ProductList, SubProduct} from '../../Utils/Constants';
import {DownloadData} from '../../Utils/UserUtils';
import {useDispatch, useSelector} from 'react-redux';
import {chatbotLangSelector, userDataSelector} from '../../Redux/Slices/userDataSlice';
import TokenizeForm from '../../Components/TokenizeForm/TokenizeForm';
import UserChatbot from '../../Models/UserChatbot';
import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';
import DictateButton from '../../Components/MicrosoftDictatation/DictateButton';
import Typewriter from '../../Components/Typewriter/Typewriter';
import PricingTabs from '../PricingPage/Components/PricingTabs/PricingTabs';
import Products from '../../Models/Products';
import PricingPopup from '../PricingPage/PricingPopup';
import {
  generateCodeLogic,
  handleImageUpload,
  handleStreamingAndRefIndex,
  handleVoiceClassification,
  playAudioOnIphone,
  playAudioOnNonIphone,
  prepareAudioSearchParams,
  updateChatHistory,
} from './streamingUtils';

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {solarizedlight} from 'react-syntax-highlighter/dist/esm/styles/prism';
import copy from 'copy-to-clipboard';
import {StripHtmlTags} from "../../Utils/ChatHistoryUtils";
import { set } from 'lodash';
import {updateDirtyFlags} from "../../Redux/Slices/dirtyFlagsSlice";
import { showPopUpAction } from '../../Redux/Slices/loginPopUpSlice';
import SourceCards from './SourceCards';
import { languageOptions } from '../../Utils/LanguageList';

// import DictateButton from 'react-dictate-button';
let currentSessionId: number;
type ChatHistory = ChatMessage[];
const audioVideoQueue: AudioVideoQueue = {};
let refIndex = 0;

let isWaitingForGeneration = false;
const WAIT_ALERT_MESSAGE = "Please wait a moment before sending next message..";

let full_message = "";
let mountTime: Date;

let isTypingCompleteForImageGeneration = false;


interface Conver {
  sender_message: string;
  response_message: string;
}
interface ChatComponentProps {
  setOpenSetting: (val: boolean) => void;
}


function ChatScreenPage({ setOpenSetting }: ChatComponentProps) {
  const [isVisible, setIsVisible] = useState(true);

  // UseEffect hook to hide the icon after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000); // 3000ms = 3 seconds

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);
  const [showPreview, setShowPreview] = useState(false);
  const togglePreview = () => {
    setShowPreview(!showPreview);
  };
  const userData = useSelector(userDataSelector);
  const location = useLocation();
  const {chatbotName} = useParams<{chatbotName:string}>();
  const queryParams = new URLSearchParams(location.search);
  const serializedData = queryParams.get("id");
  const chatbotId: number = Number(serializedData);
  const [chatbotData, setChatbotData] = useState<ChatbotData | UserChatbot>();
  const [conversationData, setConversationData] = useState<ConversationData>();
  const [selectedVoice, setSelectedVoice] = useState("");
  const [selectedImages, setSelectedImages] = useState<string[] | undefined>(
    []
  );
  const [showModal, setShowModal] = useState(false);
  const [isImageGenerating, setIsImageGenerating] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(new Products());
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [isOfficial, setIsOficial] = useState(false);
  const [hasUserChatted, setHasUserChatted] = useState(false);
  const [selectedLanguageOption, setSelectedLanguageOption] = useState({ value: "english", label: "English", msLangCode: "en-US", icon: "twemoji:flag-us-outlying-islands" });
  const chatbotLanguage = useSelector(chatbotLangSelector);
  useEffect(() => {
    if(chatbotLanguage)
      setSelectedLanguageOption(chatbotLanguage);
  }, [chatbotLanguage]);



  // Model selection variables
  const modelOptions = [
    // { value: "1", label: "Llama3.1-8B" },
    // { value: "2", label: "Llama3.1-70B" },
    { value: "4", label: "GPT-4o" },
    { value: "1", label: "Llama3.1-405B" },

    { value: "5", label: "Claude-Sonnet" },
  ];
  const [selectedModelOption, setSelectedModelOption] = useState({ value: "4", label: "GPT-4o"  });


  const onShowPayment = () => {
    setShowPaymentScreen(true);
  };
  const onHidePayment = () => {
    setShowPaymentScreen(false);
  };
  const handleShowmodal = () => setShowModal(true);
  const dictateButtonRef = useRef(null);
  const composerRef = useRef();

  // const startDictation = () => {
  //   if (dictateButtonRef.current) {
  //     // toast.error("yest");
  //     dictateButtonRef.current.setStarted(true);
  //   }
  // };

  const stopDictation = () => {
    if (dictateButtonRef.current) {
      dictateButtonRef.current.setStarted(false);
    }
  };

  const startAudio = () => {
    if (composerRef.current) {
      toast.error("starting...");
      composerRef.current.startRecognition();
    } else {
      toast.error("composerRef.current is null");
    }
  };

  const api_key = import.meta.env.VITE_OPENAI_API_KEY;
  function UpdateChatbot(chatbot: UserChatbot | ChatbotData) {
    if (chatbot.user_id > 0) {
      UpdateUserChatbot(chatbot).then(() => { dispatch(updateDirtyFlags({ BotDataLoaded: true })); });
    } else {
      axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}updateChatbot`,chatbot).then((response) => { dispatch(updateDirtyFlags({ BotDataLoaded: true })); });
    }
  }

  const [speechServices, setSpeechServices] = useState(null);

  const SpeechGrammarList = speechServices
  ? speechServices.SpeechGrammarList
  : null;
const SpeechRecognition = speechServices
  ? speechServices.SpeechRecognition
  : null;

  useEffect(() => {
    const initializeSpeechServices = async () => {
      const services = await createPonyfill({
        credentials: {
          region: `${import.meta.env.VITE_AZURE_LOCATION}`,
          subscriptionKey: `${import.meta.env.VITE_AZURE_KEY}`,
        },
      });
      setSpeechServices(services);
    };

    initializeSpeechServices();
  }, []);

  // const SpeechGrammarList = speechServices
  //   ? speechServices.SpeechGrammarList
  //   : null;
  // const SpeechRecognition = speechServices
  //   ? speechServices.SpeechRecognition
  //   : null;

  const handleDictate = ({ result }) => {
    if (isListening == true && isRecord1 == true) {
      try {
        updateMessageToSend(result.transcript);

        setIsVoiceToVoice(true);
        setMessage(result.transcript);

        if(result.transcript === "Play."){
          setMessage("");
          return;
        }
        else {
          streamTextGeneration(true);
        }
        
      } catch (error) {
        console.error("Error in handleDictate:", error);
      }
    }
  };

  const handleProgress = ({ results }) => {
    try {
      if (userHangUp == true || isRecord1 == false) {
        // setIsListening(false);
        return;
      }
      setIsListening(true);
      const interimTranscript = results
        .map((result) => result.transcript)
        .join(" ");
      setMessage(interimTranscript);
    } catch (error) {
      console.error("Error in handleProgress:", error);
    }
  };

  async function updateChatbotAudioAndDescription(chatbot: ChatbotData | UserChatbot) {
    if (!chatbot.audio_url || chatbot.audio_url.trim() === "") {
      const audio_url = await ClassifyVoice(chatbot.system_prompt);
      chatbot.audio_url = audio_url;
      setSelectedVoice(audio_url);
      setIsVoiceClassified(true);
    } else {
      setSelectedVoice(chatbot.audio_url);
      setIsVoiceClassified(true);
    }

    if (!chatbot.description || chatbot.description.trim() === "" || chatbot.description === "test") {
      const description = await SummarizeDescription(chatbot.system_prompt);
      chatbot.description = description;
      setSummarizedDescription(description);
    } else {
      setSummarizedDescription(chatbot.description);
    }

    if (!chatbot.long_description || chatbot.long_description.trim() === "") {
      const longDescription = await GenerateLongDescription(chatbot.system_prompt);
      chatbot.long_description = longDescription;
      setInputPrompt(longDescription);
    } else {
      setInputPrompt(chatbot.long_description);
    }

    UpdateChatbot(chatbot);
  }

  async function InitializeChatbotData(chatbot: ChatbotData | UserChatbot) {
    let hasTalked = false;

    if (chatbot.user_id === undefined) {
      // Scrapped chatbot
      hasTalked = await HasUserTalkedWithChatbot(chatbot.chatbot_id);
      setHasUserChatted(hasTalked);
      // Initialize Chat Session
      setStatsData((prev) => {
        const updatedStatsData = {
          ...prev,
          chat_id: chatbot.chatbot_id,
        };
        sendingStat(updatedStatsData);
        return updatedStatsData;
      });
    } else {
      // User chatbot
      hasTalked = await HasUserTalkedWithChatbot(chatbot.id);
      setHasUserChatted(hasTalked);
      // Initialize Chat Session

      setStatsData((prev) => {
        const updatedStatsData = {
          ...prev,
          chat_id: chatbot.id,
        };
        sendingStat(updatedStatsData);
        return updatedStatsData;
      });
    }
    setIsOficial(chatbot.source && chatbot.source === 4);
    setChatbotData(chatbot);
    InitConversationsHistory(chatbot);
    setInputPrompt(chatbot.system_prompt.slice(0, 1500));
    await updateChatbotAudioAndDescription(chatbot);
  }

  useEffect(() => {
    if (chatbotId !== undefined && chatbotId !== null && chatbotId > 0) {
      GetUserChatbotById(chatbotId).then((chatbot: UserChatbot) => { InitializeChatbotData(chatbot); });
    }
    else {
      GetFeaturedChatbotByName(chatbotName).then((chatbot: ChatbotData) => { InitializeChatbotData(chatbot); });
    }
  }, [chatbotName]);

  function InitConversationsHistory(chatbot){
    if (chatbot.chatbot_id !== undefined) {
      GetConversations(chatbot.chatbot_id).then(
        (conversations: ConversationData) => {
          setConversationData(conversations);
        }
      );
    } else if (chatbot.user_id !== undefined) {
      GetConversations(chatbot.id).then(
        (conversations: ConversationData) => {
          setConversationData(conversations);
        }
      );
    }
  }

  // Orbox system
  const [dirty, setDirty] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (dirty) {
      DownloadData(dispatch);
      setDirty(false);
    }
  }, [dirty]);

  const [isChatScreenAiAgentImgClicked, setIsChatScreenAiAgentImgClicked] =
    useState(0);
  const [activeTab, setActiveTab] = useState("static");
  const [message, setMessage] = useState("");

  const [chatHistory, setChatHistory] = useState<ChatHistory>([]);
  const [chatHistoryForApps, setChatHistoryForApps] = useState<
    string | undefined
  >("");
  const [chatHistoryForCodeGen, setChatHistoryForCodeGen] = useState<
    string | undefined
  >("");
  const [openAIChatHistory, setOpenAIChatHistory] = useState([]);
  const [openShare, setOpenShare] = useState(false);
  const [summarizedDescription, setSummarizedDescription] = useState("");
  const [isVoiceClassified, setIsVoiceClassified] = useState(false);

  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const [isRecord1, setIsRecord1] = useState(false);
  const [isRecord2, setIsRecord2] = useState(false);

  const [inputPrompt, setInputPrompt] = useState(
    "You are Danny Trejo, talk & act like the legendary danny trejo! You always have a positive attitude and if it makes sense!"
  );

  const resultRef = useRef(null);
  const numberOfRefs = 200;
  let refArray = Array.from({ length: numberOfRefs }, () => useRef());
  const audioContainerRef = useRef(null);

  // Long Text Generation Solution
  const [longGenerationWaiting, setLongGenerationWaiting] = useState(false);
  const [showingThisText, setShowingThisText] = useState("");
  const [
    whileStreamingCurrentIndexTextStream,
    setWhileStreamingCurrentIndexTextStream,
  ] = useState(0);



  const [chatbotImage, setChatbotImage] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);
  const [timerRate, setTimerRate] = useState(4000);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { username } = useParams();
  // Initialize Firebase
  // const db = GetFirestoreInstance();

  // ***** NEW AUDIO STREAM ***** //
  const DEFAULT_TEXT = "Tell me a joke about AI.";
  const [audioSrc, setAudioSrc] = useState<string>("");
  const [isMuted, setIsMuted] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const imageRef = useRef(null);
  const [isListening, setIsListening] = useState(false);
  const [isVoiceToVoice, setIsVoiceToVoice] = useState(false);
  const [scale, setScale] = useState(1);
  const [talkingState, setTalkingState] = useState("idle");
  const [hasRan, setHasRan] = useState(false);
  const [textStream, setTextStream] = useState([]);
  const [currentIndexTextStream, setCurrentIndexTextStream] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stringToShow, setStringToShow] = useState("");
  const [isStreaming, setIsStreaming] = useState(
    Array.from({ length: numberOfRefs }, () => false)
  );
  console.log({ talkingState, isListening })
  const [mobileVoiceScreen, setMobileVoiceScreen] = useState(false);
  const [iphoneUrl, setIphoneUrl] = useState("");

  const [showHelloModal, setShowHello] = useState(true);
  const handleCloseHello = () => setShowHello(true);
  const sayHelloOnce = useRef(false);
  const onEndExecuted = useRef(false);

  const [userHangUp, setUserHangUp] = useState(true);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleTypingComplete = () => {
    console.log("Typing completed");
    setIsReset(false)
    setIsTypingComplete(true);
    isTypingCompleteForImageGeneration = true;
    setIsStreamingForIndex(refIndex, false);
    // setTalkingState('idle')
    const nextStringToShow = `${stringToShow}${textStream[currentIndexTextStream]}`;
    let nextStringToShowProcessed =
      `${stringToShow}${textStream[currentIndexTextStream]}`.replace(
        /\[END\]\d+/g,
        ""
      );
    nextStringToShowProcessed =
      `${stringToShow}${textStream[currentIndexTextStream]}`.replace("[E", "");
    nextStringToShowProcessed =
      `${stringToShow}${textStream[currentIndexTextStream]}`.replace("ND]", "");

    const newChatMessage: ChatMessage = {
      text: nextStringToShowProcessed,
      sender: "system",
      profile: chatbotImage, // Replace with actual data
      audioUrl: "", // Replace with actual data
      time: formatTime(new Date()),
    };
    addMessageToOpenAIChat(newChatMessage);


    // toast.error('Typing complete');
  };

  const isIphone = () => {
    return /iPhone/.test(navigator.userAgent);
  };

  function findEndNumber(arr) {
    // Regular expression to match '[END]' followed by any number of digits
    const regex = /\[END\](\d+)/;

    // Find the first element that matches the pattern and return the number after '[END]'
    for (const element of arr) {
      const match = element.match(regex);
      if (match) {
        return parseInt(match[1], 10); // Convert the matched number to an integer and return it
      }
    }

    // Return null if no match is found
    return null;
  }


  const setIsStreamingForIndex = (index, value) => {
    const updatedIsStreaming = [...isStreaming];
    updatedIsStreaming[index] = value;
    setIsStreaming(updatedIsStreaming);
  };

  const toggleMute = () => {
    const currentlyMuted = !isMuted;
    setIsMuted(currentlyMuted);
    if (audioRef.current) {
      audioRef.current.muted = currentlyMuted;
    }
  };

  // ***** image 2 app logic ***** //

  const [imageFilesWithPreviews, setImageFilesWithPreviews] = useState<
    ImageFileWithPreview[]
  >([]);
  const [appState, setAppState] = useState<AppState>(AppState.INITIAL);
  const [generatedCode, setGeneratedCode] = useState<string>("");
  const [currentVersion, setCurrentVersion] = useState<number | null>(null);
  const [executionConsole, setExecutionConsole] = useState<string[]>([]);
  const [updateInstruction, setUpdateInstruction] = useState("");
  const [assetGenData, setAssetGenData] = useState<AssetData[]>([]);
  const [appHistory, setAppHistory] = useState<History>([]);
  const [show, setShow] = useState(false);
  const [messagesCounter, setMessagesCounter] = useState(0);
  const [imageForS3Upload, setImageForS3Upload] = useState();
  const [generateForCode, setGenerateForCode] = useState("");
  const [metadataSourcesRAG, setMetadaSourcesRAG] = useState<any>(null);


  const statsChatbotId =
    chatbotData?.chatbot_id !== undefined
      ? chatbotData.chatbot_id
      : chatbotData?.id;
  // Variables for chat stats
  const [statsData, setStatsData] = useState<StatsData>({
    sent_messages_audio: 0,
    chat_id: statsChatbotId,
    creator_user_id: chatbotData?.user_id !== undefined && chatbotData?.user_id !== 0 ? chatbotData?.user_id : 0,
    user_id: userData?.UserId,
    session_id: 0,
    conversation_id: 0,
    first: true,
    last_message_sender: "",
    last_message_chat: "",
    delete_stat: true,
  });

  const statsDataRef = useRef(statsData);

  useEffect(() => {
    if (!statsData.first) {
      const timer = setTimeout(() => {
        sendingStat(statsData);
        statsDataRef.current = statsData;
      }, 10000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [statsData]);

  useEffect(() => {
    const lastTextSender = chatHistory[chatHistory.length - 2]?.text;

    const updatedData = {
      ...statsData,
      sent_messages_audio: messagesCounter,
    };
    setStatsData(updatedData);

    if (lastTextSender) {
      const lastTextChat = chatHistory[chatHistory.length - 1]?.text;

      if (lastTextChat) {
        const messageId = parseInt(lastTextChat as string);
        if (refArray[messageId]?.current) {
          let finalMessage = refArray[messageId]?.current;
          if (finalMessage?.substring(0, 9) === "undefined") {
            finalMessage = finalMessage.substring(9);
          }
          sendingStat(updatedData, finalMessage, lastTextSender);
        } else {
          console.error("No element at this position in refArray");
        }
      }
    }

    statsDataRef.current = statsData;
  }, [currentIndex]);

  useEffect(() => {
    sendingStat(statsData);
    return () => {
      const finalStatsData = statsDataRef.current;
      finalStat(finalStatsData).then();
    };
  }, []);

  const sendingStat = (data: StatsData, last_chat = "", last_sender = "") => {
    // Return if the data is not valid
    if (data.chat_id === undefined || data.chat_id === 0 || data.user_id === 0 ) {
      return;
    }
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("oauthToken"),
      },
    };

    data.last_message_chat = last_chat;
    data.last_message_sender = last_sender;

    const url = import.meta.env.VITE_SERVER_ADDRESS + "startSendingStat";

    if (data.first)
    {
      //dispatch(updateDirtyFlags({ RecentChatbotsDirty: true }));
    }

    axios.post(url, data, config)
      .then(async (response) => {
        currentSessionId = response.data;
        const updatedData = {
          ...data,
          session_id: response.data,
          "sent_messages_audio": messagesCounter,
          "first": false,
        };
        setStatsData(updatedData);
        dispatch(updateDirtyFlags({ CurrentChatbotDirty: true }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const finalStat = async (data: StatsData) => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("oauthToken"),
      },
    };

    const url = import.meta.env.VITE_SERVER_ADDRESS + "endSendingStat";
    return axios
      .post(url, data, config)
      .then(() => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const finalStatHistory = (data: StatsData) => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("oauthToken"),
      },
    };

    data.keep_stat = true;

    const url = import.meta.env.VITE_SERVER_ADDRESS + "endSendingStat";
    axios
      .post(url, data, config)
      .then(async () => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [settings, setSettings] = usePersistedState<Settings>(
    {
      isImageGenerationEnabled: true,
      generatedCodeConfig: GeneratedCodeConfig.GENERIC,
      accessCode: null,
    },
    "setting"
  );
  const [shouldIncludeResultImage, setShouldIncludeResultImage] =
    useState<boolean>(true);

  const wsRef = useRef<WebSocket>(null);

  const setReferenceImages = (file: File) => {
    if (file instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64DataUrl = reader.result as string;
        setImageFilesWithPreviews((prev) => [
          ...prev,
          { file, dataUrl: base64DataUrl },
        ]);
        setImageForS3Upload(file);
      };
    } else {
      console.error("setReferenceImages: Argument is not a File object", file);
    }
  };

  const removeImage = (imageIndex: number) => {
    setImageFilesWithPreviews((prevImages) => {
      URL.revokeObjectURL(prevImages[imageIndex].dataUrl);
      return prevImages.filter((_, index) => index !== imageIndex);
    });
  };

  const cancelCodeGeneration = () => {
    wsRef.current?.close?.(USER_CLOSE_WEB_SOCKET_CODE);
    // make sure stop can correct the state even if the websocket is already closed
    cancelCodeGenerationAndReset();
  };

  const cancelCodeGenerationAndReset = () => {
    // When this is the first version, reset the entire app state
    if (currentVersion === null) {
      reset();
    } else {
      // Otherwise, revert to the last version
      setGeneratedCode(appHistory[currentVersion].code);
      setAppState(AppState.CODE_READY);
    }
  };



  const startListening = () => {
    // setCurrentMessage("");
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const AUDIO_ORBOX_QUANTITY = 1;

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.interimResults = true;

      recognition.onresult = (event: any) => {
        let interimTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            // setCurrentMessage((prev) => prev + transcriptionSegment);
          } else {
            // console.log(transcriptionSegment);
            interimTranscript += transcriptionSegment;
            setMessage(interimTranscript);
            full_message = interimTranscript;
          }
        }

      };

      recognition.onstart = () => {
        setIsListening(true);
        setTalkingState("listening");
        setIsVoiceToVoice(true);
      };

      recognition.onend = async () => {
        setIsListening(false);
        streamTextGeneration(false);
      };

      recognition.start();
    } else {
      toast.error("Speech Recognition API not supported in this browser.");
    }
  };
  // When the user already has the settings in local storage, newly added keys
  // do not get added to the settings so if it's falsy, we populate it with the default
  // value
  useEffect(() => {
    if (!settings.generatedCodeConfig) {
      setSettings((prev) => ({
        ...prev,
        generatedCodeConfig: GeneratedCodeConfig.HTML_TAILWIND,
      }));
    }
  }, [settings.generatedCodeConfig, setSettings]);

  const reset = () => {
    setAppState(AppState.INITIAL);
    setGeneratedCode("");
    setGenerateForCode("");
    // setReferenceImages([]);

    setExecutionConsole([]);
    if (settings.generatedCodeConfig === "game_gen") {
      setAssetGenData([]); // Clear generated assets only if game_gen is selected
    }
    setAppHistory([]);
  };

  const stop = () => {
    wsRef.current?.close?.(USER_CLOSE_WEB_SOCKET_CODE);
    // make sure stop can correct the state even if the websocket is already closed
    setAppState(AppState.CODE_READY);
  };


  // for website generation feature
  async function doGenerateCode(
    params: CodeGenerationParams,
    parentVersion: number | null
  ) {
    setExecutionConsole([]);
    setAppState(AppState.CODING);

    if (!handleVoiceClassification(isVoiceClassified, setIsTyping)) {
      return;
    }

    refIndex = handleStreamingAndRefIndex(
      isStreaming,
      refIndex,
      hasRan,
      setIsStreamingForIndex
    );

    let newChatHistory = await updateChatHistory(params, chatHistory, api_key);

    // setTalkingState("thinking");
    setStringToShow("");
    setCurrentIndexTextStream(0);
    setWhileStreamingCurrentIndexTextStream(0);
    setIsTypingComplete(false);
    isTypingCompleteForImageGeneration = false;
    setLongGenerationWaiting(true);
    setTextStream([]);
    setIsTyping(true);
    setMessage("");
    setImageFilesWithPreviews([])
    const onErrorInStream = (error) => {
      try {
        console.trace();
        setLoading(false);
        setTalkingState("idle");
        // setIsTyping(false);
        setIsErrored(true);
        isWaitingForGeneration = false;

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        
    
        if (error) {
          console.error("Error in stream:", error);
          toast.error(`Error: ${error.message || "An error occurred"}`);
        } else {
          console.error("Unknown error in stream");
          toast.error("Unknown error occurred during audio playback or streaming.");
        }
    
      } catch (innerError) {
        console.error("Error handling failed:", innerError);
        toast.error("An error occurred while handling another error.");
      }
    };


    try {
      const myUUID = uuidv4();
      const convertedMessages = convertMessagesForWaitAppGenerator(
        newChatHistory,
        inputPrompt
      );

      const isUserOnIphone = isIphone();
      const searchParams = prepareAudioSearchParams(myUUID, convertedMessages, inputPrompt, chatbotData, selectedVoice, isUserOnIphone, selectedModelOption['label'], false, selectedLanguageOption.value, isMuted);


      if (isUserOnIphone) {
        playAudioOnIphone(searchParams, audioContainerRef, setTalkingState, undefined, true);
      } else {
        const url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
        playAudioOnNonIphone(url, audioRef, onErrorInStream, setTalkingState, isMuted);
      }

      setLoading(true);
      setMessage("");
      // setTalkingState("thinking");

      const newChatMessage: ChatMessage = {
        text: refIndex.toString(),
        sender: "system",
        profile: chatbotImage,
        audioUrl: "",
        time: formatTime(new Date()),
      };

      let isAdded = false;
      const intervalId = setInterval(async () => {
        try {
          const response = await fetch(
            `${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              },
            }
          );
          if (!response.ok) {
            throw new Error(`Error fetching data: ${response.statusText}`);
          }
          const data = await response.json();
          const data_array = data["array_data"];
          const totalSentences = findEndNumber(data_array);

          resultRef.current = data_array.join(" ");
          refArray[refIndex].current = data_array.join(" ");
          setTextStream(data_array.join(" "));

          if (totalSentences != null) {
            if (!isAdded) {
              isAdded = true;
              addMessageToChat(newChatMessage);
            }
            clearInterval(intervalId);
            refArray[refIndex].current = data_array.slice(0, -1).join(" ");
            setCurrentIndex(currentIndex + 1);
            setMessagesCounter(messagesCounter + 1);
            // setTalkingState("idle");
            setHasRan(true);
            isAudioFinished = true;

            generateCodeLogic(
              params,
              parentVersion,
              settings,
              wsRef,
              setGeneratedCode,
              setAppHistory,
              setCurrentVersion,
              setExecutionConsole,
              setAppState,
              setIsTyping,
              updateInstruction,
              imageFilesWithPreviews,
              AppState
            );
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }, 1000);
    } catch (error) {
      console.error(`hm, there seems to be an error in image to app, contact support. ${error}`);
    }
  }

  function doCreate(inputType: "text" | "image", textPrompt?: string) {
    reset(); // Reset any existing state

    if (inputType === "image" && imageFilesWithPreviews.length > 0) {
      const params: CodeGenerationParams = {
        generationType: "create",
        image: imageFilesWithPreviews[0].dataUrl,
        inputType: inputType,
      };
      const newChatHistory = [
        ...chatHistory,
        {
          text: "",
          sender: "user",
          profile: "/images/profile.png",
          audioUrl: "",
          imageUrl: imageFilesWithPreviews[0].dataUrl
        },
      ];

      setChatHistory(newChatHistory as []);
      doGenerateCode(params, currentVersion);
    } else if (inputType === "text" && textPrompt?.trim() !== "") {
      setChatHistoryForApps(textPrompt);
      const newChatHistory = [
        ...chatHistory,
        {
          text: textPrompt,
          sender: "user",
          profile: "/images/profile.png",
          // profile: userData?.ProfilePicture ?? "/images/profile.png",
          audioUrl: "",

        },
      ];

      setChatHistory(newChatHistory as []);

      const params: CodeGenerationParams = {
        generationType: "create",
        textPrompt: textPrompt,
        inputType: inputType,
      };
      doGenerateCode(params, currentVersion);
    } else {
      toast.error("Please provide an image or text");
    }
  }

  // Subsequent updates
  async function doUpdate(instructions: string) {
    if (currentVersion === null) {
      toast.error("No current version set.");
      return;
    }

    let historyTree;
    try {
      historyTree = extractHistoryTree(appHistory, currentVersion);
    } catch {
      toast.error("Version history is invalid.");
      return;
    }

    const newChatHistory = [
      ...chatHistory,
      {
        text: instructions,
        sender: "user",
        profile: "/images/profile.png",
        // profile: userData?.ProfilePicture ?? "/images/profile.png",
        audioUrl: "",
      },
    ];

    setChatHistory(newChatHistory as []);
    setChatHistoryForApps(instructions);
    const updatedHistory = [...historyTree, instructions];

    const image = imageFilesWithPreviews[0]?.dataUrl; // Use optional chaining to safely access dataUrl
    const resultImage = await takeScreenshot();
    if (shouldIncludeResultImage && image) {
      doGenerateCode(
        {
          generationType: "update",
          image: image,
          resultImage: resultImage,
          history: updatedHistory,
        },
        currentVersion
      );
    } else if (image) {
      doGenerateCode(
        {
          generationType: "update",
          image: image,
          history: updatedHistory,
        },
        currentVersion
      );
    } else {
      // Handle text update
      doGenerateCode(
        {
          generationType: "update",
          textPrompt: instructions,
          resultImage: resultImage,
          history: updatedHistory,
        },
        currentVersion
      );
    }

    setGeneratedCode("");
    setUpdateInstruction("");
  }


  // **********************************************************                ********************************************************** //

  React.useEffect(() => {
    if (countDown > 100 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  React.useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(0);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown + 1);
      }, timerRate);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer, timerRate]);

  const isSkipped = false;
  let isAudioFinished = false;
  const defaultData = null;

  // var talkingHeadIndex = 0;
  const needToShowAnswer = false;
  const hasShownResponse = false;

  const chatFieldRef = useChatScroll(chatHistory);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    const chatField = chatFieldRef.current;
    if (chatField) {
      (chatField as HTMLElement).scrollTop = (
        chatField as HTMLElement
      ).scrollHeight;
    }
  };

  useEffect(() => {
    const chatContainer = chatFieldRef.current;
    if (chatContainer) {
      scrollToBottom();

      // ResizeObserver to handle dynamic height changes
      // const observer = new ResizeObserver(scrollToBottom);
      // if (chatContainer) {
      //   observer.observe(chatContainer);
      // }

      // // Clean up observer
      // return () => {
      //   if (chatContainer) {
      //     observer.unobserve(chatContainer);
      //   }
      // };
    }
  }, [chatHistory, refArray]);

  const onClickChatScreenAiAgentImg = (val: number) => {
    setIsChatScreenAiAgentImgClicked(val);
    setActiveTab("static");
  };

  // This only adds the message from the system to the chat history
  function addMessageToChat(newMessage: ChatMessage) {
    setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
    const messageToSave = { ...newMessage, session_id: currentSessionId };
    messageToSave.text = refArray[newMessage.text]?.current;
    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}logMessage`,JSON.stringify(messageToSave), config);
  }

  function removeLastMessageFromChat() {
    setChatHistory((prevChatHistory) => prevChatHistory.slice(0, -1));
  }


  function addMessageToOpenAIChat(newMessage: ChatMessage) {
    setOpenAIChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
  }

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };


  const [screenSize, setScreenSize] = useState(0);
  useEffect(() => {
    setScreenSize(window.innerWidth);
    window.addEventListener("resize", () => setScreenSize(window.innerWidth));
    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", () =>
        setScreenSize(window.innerWidth)
      );
    };
  }, []);

  const updateMessageToSend = (text) => {
    full_message = text;
  };

  const translateMessages = async (messages, targetLanguage) => {
    try {
      const response = await axios.post('https://98sylryzyqxvtf-4000.proxy.runpod.net/translate', {
        messages: messages,           // The messages to be translated
        target_language: targetLanguage.toLowerCase() // The target language (from selectedLanguage)
      });

       return response.data; // Set the translated messages to the state
    } catch (error) {
      console.error('Error translating messages:', error);
    }
  };



  const streamTextGeneration = async (isVoiceToVoiceActivated: boolean) => {
    
    if(isWaitingForGeneration) {
      toast.error(WAIT_ALERT_MESSAGE);
      setMessage('');
      return;
    }

    isWaitingForGeneration = true;
    setIsErrored(false);
    console.log({ isVoiceToVoiceActivated })
    setIsListening(isVoiceToVoiceActivated);
    
    // var messageToSend = full_message || message;
    var messageToSend = "";

    if (full_message != "") {
      messageToSend = full_message;
      full_message = "";
    }
    else {
      messageToSend = message;
    }

    if (isStreaming[refIndex]) {
      isStreaming[refIndex] = false;
    }

    if (hasRan) {
      refIndex += 1;
    }

    if (!handleVoiceClassification(isVoiceClassified, setIsTyping)) {
      return;
    }

    if (messageToSend.length < 1) {
      // setTalkingState("idle");
      setIsVoiceToVoice(false);
      setIsListening(false);
      toast.error("Make sure input text is greater than 1 character", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      return;
    }

    let imageforVision = imageFilesWithPreviews[0]?.dataUrl;
    let s3Url;



    if (imageforVision) {
      setIsTyping(true);
      s3Url = await handleImageUpload(imageForS3Upload);
    }

    const userMessage = {
      text: messageToSend,
      sender: "user",
      profile: "/images/profile.png",
      audioUrl: "",
      imageUrl: s3Url ? s3Url : "",
      session_id: statsData.session_id,
    };

    const newChatHistory = [
      ...chatHistory,
      userMessage
    ];

    const config = { headers: { "Authorization": "Bearer " + localStorage.getItem("oauthToken"), }, };
    axios.post(`${import.meta.env.VITE_SERVER_ADDRESS}logMessage`,JSON.stringify(userMessage), config);
    await RegisterOrboxUse(ProductList.ChatBot, true, 1, SubProduct.ChatBot.ImageGeneration).then(() => {DownloadData(dispatch); });
    setChatHistory(newChatHistory as []);
    setStringToShow("");
    setCurrentIndexTextStream(0);
    setWhileStreamingCurrentIndexTextStream(0);
    setIsTypingComplete(false);
    isTypingCompleteForImageGeneration = false;
    setLongGenerationWaiting(true);
    setTextStream([]);
    setIsTyping(true);
    setMessage("");
    setImageFilesWithPreviews([]);

    const onErrorInStream = (error) => {
      try {
        console.trace();
        setLoading(false);
        setTalkingState("idle");
        // setIsTyping(false);
        setIsErrored(true);
        isWaitingForGeneration = false;

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        
    
        if (error) {
          console.error("Error in stream:", error);
          setIsTyping(true);
          // toast.error(`Error: ${error.message || "An error occurred"}`);
        } else {
          console.error("Unknown error in stream");
          // toast.error("Unknown error occurred during audio playback or streaming.");
        }
    
      } catch (innerError) {
        console.error("Error handling failed:", innerError);
        // toast.error("An error occurred while handling another error.");
      }
    };


    try {
      const uuid = uuidv4();
      // Step 2: Get the current time
      const now = new Date();
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      // Step 3: Concatenate UUID and time
      const myUUID = `${uuid}-${minutes}${seconds}`;
      let convertedMessages = chatbotData?.rag
        ? convertRAGMessages(newChatHistory, inputPrompt, userData.Name, refArray)
        : convertMessagesShort(newChatHistory, inputPrompt, userData.Name, refArray);

      // console.log(convertedMessages);
      const isUserOnIphone = isIphone();
      // if (selectedLanguageOption['value']) {
      //   convertedMessages = await translateMessages(convertedMessages, selectedLanguageOption['value']);
      // }

      // console.log(convertedMessages);
      const searchParams = prepareAudioSearchParams(myUUID, convertedMessages, inputPrompt, chatbotData, selectedVoice, isUserOnIphone, selectedModelOption['label'], s3Url ? true : false, selectedLanguageOption.value, isMuted);

      const src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;

      let metadataFromAudio: any = null;

      // console.log("chatbotData.rag_source_type)", chatbotData?.rag_source_type)


      try {
        if (isUserOnIphone) {
          playAudioOnIphone(searchParams, audioContainerRef, setTalkingState, (metadata) => {
            // console.log("metadataFromAudioplayAudioOnIphone", metadata);
            setMetadaSourcesRAG(metadata);
            metadataFromAudio = metadata;
          });
        }
         else {
          const url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
          setIsTyping(true);
          // playAudioOnNonIphone(url, audioRef, onErrorInStream, setTalkingState, setMetadaSourcesRAG);
          playAudioOnNonIphone(
            url,
            audioRef,
            onErrorInStream,
            setTalkingState,
            (metadata) => {
              // This callback will be called when metadata is ready
              setMetadaSourcesRAG(metadata);
              metadataFromAudio = metadata;


            },
            isMuted


          );

        }
      } catch (error) {
        toast.error("An error occurred while trying to play the audio:");
        setIsTyping(false);
        isWaitingForGeneration = false;
        setCurrentIndex(currentIndex + 1);
        setMessagesCounter(messagesCounter + 1);
        setTalkingState("idle");
        setHasRan(true);
        isAudioFinished = true;
        return;

        // You can also add any other error handling logic here, like displaying an error message to the user
      }

      setLoading(true);
      // setTalkingState("thinking");


      let newChatMessage: ChatMessage = {
        text: refIndex.toString(),
        sender: "system",
        profile: chatbotImage,
        audioUrl: "",
        time: formatTime(new Date()),
        ragSources: null

      };

      let isAdded = false;
      setIsStreamingForIndex(refIndex, true);

      let generatingImages = false;
      let generatingImagesLoader = false;

      const intervalId = setInterval(async () => {
        try {
          const response = await fetch(
            `${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              },
            }
          );
          if (!response.ok) {
            throw new Error(`Error fetching data: ${response.statusText}`);
          }
          const data = await response.json();
          const data_array = data["array_data"];
          const totalSentences = findEndNumber(data_array);
          const newTextStream = data_array.join(" ");

          console.log(newTextStream);

          if (newTextStream.length > 1) {
            setIsTyping(false);
          }

          if(newTextStream.includes('--') && generatingImagesLoader == false && !isTyping) {
            setIsImageGenerating(true);
            generatingImagesLoader = true;
            console.log("Activating image generation");
          }

          // if (newTextStream.length > 200) {
          //   isWaitingForGeneration = false;
          // }

          resultRef.current = newTextStream.replace("Treho", "Trejo");
          refArray[refIndex].current = newTextStream.replace("Treho", "Trejo");

          setTextStream(data_array.join(" ").replace("Treho", "Trejo"));

          if (totalSentences != null) {
            if (!isAdded) {
              isAdded = true;
              newChatMessage = {
                ...newChatMessage,
                ragSources: metadataFromAudio || null
              };

              addMessageToChat(newChatMessage);
              setIsTyping(false);
              isWaitingForGeneration = false;
              // isWaitingForGeneration = false;
            }
            clearInterval(intervalId);
            refArray[refIndex].current = data_array
              .slice(0, -1)
              .join(" ")
              .replace("Treho", "Trejo");
            setCurrentIndex(currentIndex + 1);
            setMessagesCounter(messagesCounter + 1);
            // setTalkingState("idle");
            setHasRan(true);
            isAudioFinished = true;

            await axios.post(
              "https://98sylryzyqxvtf-6000.proxy.runpod.net/submit_qa",
              {
                question: messageToSend,
                response: data_array
                  .slice(0, -1)
                  .join(" ")
                  .replace("Treho", "Trejo"),
              }
            );
          }

          if(newTextStream.includes('--') && generatingImages == false) {
            generatingImages = true;

            const url = "https://api.openai.com/v1/chat/completions";
            const tmp = {
              model: "gpt-4o",
              messages: convertMessagesImageGenerationEverytime(newChatHistory, inputPrompt, messageToSend, refArray),
              temperature: 0.75,
              top_p: 0.95,
              // max_tokens: 100,
              stream: true,
              // n: 1,
            };

            const source = new SSE(url, {
              headers: {
                "Content-Type": "application/json",
                Authorization: api_key,
              },
              method: "POST",
              payload: JSON.stringify(tmp),
            });

            let full_text = "";
            let hasRun = false;

            source.addEventListener("message", (e: any) => {
              setIsRunning(true);
              scrollToBottom();


              if (e.data != "[DONE]") {

                // setIsTyping(false);
                const payload = JSON.parse(e.data);
                const text = payload.choices[0].delta["content"];
                if (text != null) {
                  full_text = full_text + text;
                }
              } else {
                if (hasRun) return;
                hasRun = true;

                var prompts = extractListItems(full_text);

                if(prompts.length < 2) {
                  console.log("no prompts...");
                  prompts = [full_text];
                }

                const images: any = [];

                // console.log("******************* PROMPTS *******************\n");

                Promise.all(prompts.map(async (prompt: string) => {

                  try {
                    const response = await fetch(`${RUNPOD_ROMANIAN_SERVER}/generate`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                      },
                      body: JSON.stringify({ prompt }), // Send the prompt in the request body
                    });

                    if (!response.ok) {
                      throw new Error(`Error fetching data: ${response.statusText}`);
                    }

                    const data = await response.json(); // Parse the response JSON

                    // return data["output"]['image_url']; // Extracting the first image from the response
                    images.push(data["output"]);
                } catch (error) {
                    console.error("Error in fetchImage:", error);
                    images.push("");
                }
                }))
                  .then(async ([imageResponses]) => {
                    setIsImageGenerating(false);
                    isWaitingForGeneration = false;
                    const newChatMessage: ChatMessage = {
                      text: "",
                      sender: "system",
                      profile: chatbotImage, // Replace with actual data
                      audioUrl: "", // Replace with actual data
                      images: images,
                      time: formatTime(new Date()),
                    };
                    addMessageToChat(newChatMessage);
                    setIsTyping(false);
                    await RegisterOrboxUse(ProductList.ChatBot, true, 3, SubProduct.ChatBot.ImageGeneration).then(() => {DownloadData(dispatch); });

                  })
                  .catch(error => {
                    // Handle any error from the entire Promise.all
                    console.error("Error in Promise.all:", error);
                    setIsLoading(false);
                  });
                source.close();
              }

            });

            return () => {
              console.log("DONE");
            };

          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
          // toast.error("Failed to fetch data, please try to send another message");
          isWaitingForGeneration = false;
          // setIsTyping(false);
          setIsLoading(false);
          clearInterval(intervalId);
          setHasRan(true);
          isAudioFinished = true;
          // removeLastMessageFromChat();
        }
      }, 1500);
    } catch (error) {
      console.log("Error:", error);
      // isWaitingForGeneration = false;
    }
  };



  const generateImages = async () => {

    if(isWaitingForGeneration) {
      toast.error(WAIT_ALERT_MESSAGE);
      return;
    }
    isWaitingForGeneration = true;

    setIsListening(false);
    // }
    var messageToSend = "";

    if (isStreaming[refIndex] == true) {
      isStreaming[refIndex] = false;
      // toast.error("Stopping & sending new message");
    }

    if (hasRan) {
      refIndex += 1;
    }

    if (isVoiceClassified == false) {
      toast.error("Voice not classified, try again in a moment or contact support");
      setIsTyping(false);
      return;
    }

    if (full_message != "") {
      messageToSend = full_message;
    }
    else {
      messageToSend = message;
    }

    if (messageToSend.length < 1) {
      // setTalkingState("idle");
      setIsVoiceToVoice(false);
      setIsListening(false);
      toast.error("Make sure input text is greater than 1 character", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
          // position: "bottom-center",
          // index: '1'
        },
      });
      return;
    }

    let imageforVision = imageFilesWithPreviews[0]?.dataUrl


    let s3Url;

    if (imageforVision) {

      setIsTyping(true);

      s3Url = await uploadImageToS3(imageForS3Upload)

    }

    const newChatHistory = [
      ...chatHistory,
      {
        text: messageToSend,
        sender: "user",
        profile: "/images/profile.png",
        // profile: userData?.ProfilePicture ?? "/images/profile.png",
        audioUrl: "",
        imageUrl: s3Url ? s3Url : ""
      },
    ];
  

  
    setChatHistory(newChatHistory as []);

    // setTalkingState("thinking");
    setStringToShow("");
    setCurrentIndexTextStream(0);
    setWhileStreamingCurrentIndexTextStream(0);
    setIsTypingComplete(false);
    isTypingCompleteForImageGeneration = false;
    setLongGenerationWaiting(true);
    setTextStream([]);
    setIsTyping(true);
    setMessage("");
    setImageFilesWithPreviews([])


      try {

        const myUUID = uuidv4();

        const isUserOnIphone = isIphone();

        if (isUserOnIphone) {
          // toast.error("user on iphone");
          const searchParams = new URLSearchParams();
          searchParams.set("prompt", "");
          searchParams.set("responseId", myUUID);
          searchParams.set("history", JSON.stringify(convertMessagesForWait(newChatHistory, inputPrompt)));
          searchParams.set("selectedVoice", selectedVoice);
          searchParams.set("introMsg", "true");
          searchParams.set("chatId", chatbotData.id);
          searchParams.set("isRAG", chatbotData.rag);
          searchParams.set("imageVision", imageFilesWithPreviews[0]?.dataUrl ? "true" : "");
          searchParams.set("isIphone", isIphone().toString());



          var src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
          // setAudioSrc(`${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`);
  
  
          playAudioOnIphone(searchParams, audioContainerRef, setTalkingState, undefined, true);
  
        } else {

          // if (!audioRef.current) return;
          // const onError = () => {
          //   setLoading(false);
          //   setTalkingState("idle");
          //   console.error("Error loading audio");
          //   toast.error("Error loading audio, browser not supported");
          // };


          // toast.error("user not on iphone");
          const searchParams = new URLSearchParams();
          searchParams.set("prompt", "");
          searchParams.set("responseId", myUUID);
          searchParams.set("history", JSON.stringify(convertMessagesForWait(newChatHistory, inputPrompt)));

          searchParams.set("selectedVoice", selectedVoice);
          searchParams.set("introMsg", "true");
          searchParams.set("chatId", chatbotData.id);
          searchParams.set("isRAG", chatbotData.rag);
          searchParams.set("imageVision", imageFilesWithPreviews[0]?.dataUrl ? "true" : "");


          var url2 = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;

          const audioElement = audioRef.current;
          audioElement.pause();
          audioElement.currentTime = 0;
          setAudioSrc(url2);
          audioRef.current.load();
          const playAudio = () => {
            audioRef.current.play().catch((error) => {
              toast.error('Error attempting to play:', error);
            });
            // toast.error("Loaded data");
            // setTalkingState("talking");
            full_message = "";

          };
          audioRef.current.addEventListener("loadedmetadata", playAudio);
        }
        setLoading(true);
        setMessage("");
        // setTalkingState("thinking");




        const newChatMessage: ChatMessage = {
          text: (refIndex).toString(),
          sender: "system",
          profile: chatbotImage, // Replace with actual data
          audioUrl: "", // Replace with actual data
          time: formatTime(new Date()),
        };
        // addMessageToChat(newChatMessage);

        setIsStreamingForIndex(refIndex, true);

        var isAdded = false;

        // Start a timer that fetches the dictionary from the Flask API every 1 second
        const intervalId = setInterval(async () => {
          try {
            const response = await fetch(`https://avr2tpclxwbwc4-6000.proxy.runpod.net/get/${myUUID}`, {
              method: "GET",
              headers: {
                "Accept": "application/json",
              },
            });
            if (!response.ok) {
              throw new Error(`Error fetching data: ${response.statusText}`);
            }
            const data = await response.json();

            const data_array = data["array_data"];

            const totalSentences = findEndNumber(data_array);


            resultRef.current = data_array.join(" ").replace("Treho", "Trejo");
            refArray[refIndex].current = data_array.join(" ").replace("Treho", "Trejo");
            setTextStream(data_array.join(" ").replace("Treho", "Trejo"));

            const newTextStream = data_array.join(" ").replace("Treho", "Trejo");

            if(newTextStream.length > 1) {
              setIsTyping(false);
              setIsImageGenerating(true);
            }

            // if (newTextStream.length > 100) {
            //   isWaitingForGeneration = false;
            // }

            if (totalSentences != null) {
              if (!isAdded) {
                isAdded = true;
                addMessageToChat(newChatMessage);
              }
              clearInterval(intervalId);

              // resultRef.current = data_array.join(' ');
              refArray[refIndex].current = data_array.slice(0, -1).join(" ");
              setCurrentIndex(currentIndex + 1);
              setMessagesCounter(messagesCounter + 1);
              // setTalkingState("idle");
              setHasRan(true);
              isAudioFinished = true;

              // Ping data to server
              await axios.post('https://98sylryzyqxvtf-6000.proxy.runpod.net/submit_qa', {
                question: messageToSend,
                response: data_array.slice(0, -1).join(" ").replace("Treho", "Trejo"),
              });
            }

          } catch (error) {
            console.error("Failed to fetch data:", error);
          }
        }, 1000);

        // setTalkingState("talking");

        const url = "https://api.openai.com/v1/chat/completions";
        const tmp = {
          model: "gpt-4o",
          messages: convertMessagesImageGenerationEverytime(newChatHistory, inputPrompt, messageToSend, refArray),
          temperature: 0.75,
          top_p: 0.95,
          // max_tokens: 100,
          stream: true,
          // n: 1,
        };

        const source = new SSE(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: api_key,
          },
          method: "POST",
          payload: JSON.stringify(tmp),
        });

        let full_text = "";
        let hasRun = false;

        source.addEventListener("message", (e: any) => {
          setIsRunning(true);
          scrollToBottom();


          if (e.data != "[DONE]") {

            // setIsTyping(false);
            const payload = JSON.parse(e.data);
            const text = payload.choices[0].delta["content"];
            if (text != null) {
              full_text = full_text + text;
            }
          } else {
            if (hasRun) return;
            hasRun = true;

            const prompts = extractListItems(full_text);

            const images: any = [];

            // console.log("******************* PROMPTS *******************\n");
  
            Promise.all(prompts.map(async (prompt: string) => {


              try {
                const response = await fetch(`${RUNPOD_ROMANIAN_SERVER}/generate`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                  },
                  body: JSON.stringify({ prompt }), // Send the prompt in the request body
                });

                if (!response.ok) {
                  throw new Error(`Error fetching data: ${response.statusText}`);
                }

                const data = await response.json(); // Parse the response JSON

                // return data["output"]['image_url']; // Extracting the first image from the response
                images.push(data["output"]);
            } catch (error) {
                console.error("Error in fetchImage:", error);
                return null; // Return null in case of an error
            }

              // const postData = {
              //   model: "dall-e-3",
              //   prompt: prompt,
              //   n: 1,
              //   size: "1024x1024"
              // };

              // return axios.post("https://api.openai.com/v1/images/generations", postData, {
              //   headers: {
              //     "Content-Type": "application/json",
              //     "Authorization": api_key
              //   }
              // })
              //   .then(response => {
              //     // Process each response
              //     images.push(response.data.data[0].url);


              //   })
              //   .catch(error => {
              //     // Handle errors
              //     console.error("Error generating image for prompt:", prompt, error);
              //     setIsLoading(false);
              //   });
            }))
              .then(async ([imageResponses]) => {
                setIsImageGenerating(false);
                isWaitingForGeneration = false;
                const newChatMessage: ChatMessage = {
                  text: "",
                  sender: "system",
                  profile: chatbotImage, // Replace with actual data
                  audioUrl: "", // Replace with actual data
                  images: images,
                  time: formatTime(new Date()),
                };
                addMessageToChat(newChatMessage);
                setIsTyping(false);


              })
              .catch(error => {
                // Handle any error from the entire Promise.all
                console.error("Error in Promise.all:", error);
                setIsLoading(false);
              });
            source.close();
          }

        });

        return () => {
          console.log("DONE");
        };
      } catch (error) {
        // console.log();
        toast.error(error);
        isWaitingForGeneration = false;
      }
    }

  const codeGeneration = async () => {

    if(isWaitingForGeneration) {
      toast.error(WAIT_ALERT_MESSAGE);
      return;
    }
    isWaitingForGeneration = true;

    reset();
    // console.log("app state ... 🦹‍♂️", appState);
    // console.log("code gen ...", message);
    setIsTyping(true);
    setAppState(AppState.CODEGEN_LOADING);

    if (!handleVoiceClassification(isVoiceClassified, setIsTyping)) {
      return;
    }

    refIndex = handleStreamingAndRefIndex(
      isStreaming,
      refIndex,
      hasRan,
      setIsStreamingForIndex
    );

    const newChatHistory = [
      ...chatHistory,
      {
        text: message,
        sender: "user",
        profile: "/images/profile.png",
        // profile: userData?.ProfilePicture ?? "/images/profile.png",
        audioUrl: "",

      },
    ];

    setChatHistory(newChatHistory as []);
    // setTalkingState("thinking");
    setStringToShow("");
    setCurrentIndexTextStream(0);
    setWhileStreamingCurrentIndexTextStream(0);
    setIsTypingComplete(false);
    isTypingCompleteForImageGeneration = false;
    setLongGenerationWaiting(true);
    setTextStream([]);
    setIsTyping(true);
    setMessage("");
    setImageFilesWithPreviews([])

    const onErrorInStream = (error) => {
      try {
        console.trace();
        setLoading(false);
        setTalkingState("idle");
        // setIsTyping(false);
        setIsErrored(true);
        isWaitingForGeneration = false;

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        
    
        if (error) {
          console.error("Error in stream:", error);
          // toast.error(`Error: ${error.message || "An error occurred"}`);
        } else {
          console.error("Unknown error in stream");
          // toast.error("Unknown error occurred during audio playback or streaming.");
        }
    
      } catch (innerError) {
        console.error("Error handling failed:", innerError);
        // toast.error("An error occurred while handling another error.");
      }
    };



    try {

      const myUUID = uuidv4();
      const convertedMessages = convertMessagesForWaitCodeGenerator(
        newChatHistory,
        inputPrompt
      );

      const isUserOnIphone = isIphone();
      const searchParams = prepareAudioSearchParams(myUUID, convertedMessages, inputPrompt, chatbotData, selectedVoice, isUserOnIphone, selectedModelOption['label'], false, selectedLanguageOption.value, isMuted);



      if (isUserOnIphone) {
        playAudioOnIphone(searchParams, audioContainerRef, setTalkingState, undefined, true);
      } else {
        const url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
        playAudioOnNonIphone(url, audioRef, onErrorInStream, setTalkingState,  isMuted,);
      }


      setLoading(true);
      setMessage("");


      const newChatMessage: ChatMessage = {
        text: refIndex.toString(),
        sender: "system",
        profile: chatbotImage,
        audioUrl: "",
        time: formatTime(new Date()),
      };

      let isAdded = false;
      const intervalId = setInterval(async () => {
        try {
          const response = await fetch(
            `${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              },
            }
          );
          if (!response.ok) {
            throw new Error(`Error fetching data: ${response.statusText}`);
          }
          const data = await response.json();
          const data_array = data["array_data"];
          const totalSentences = findEndNumber(data_array);

          resultRef.current = data_array.join(" ");
          refArray[refIndex].current = data_array.join(" ");

          const newTextStream = data_array.join(" ");

          setTextStream(data_array.join(" "));


          if (newTextStream.length > 1) {
            setIsTyping(false);
          }
          
          if (newTextStream.length > 50) {
            isWaitingForGeneration = false;
          }


          if (totalSentences != null) {
            if (!isAdded) {
              isAdded = true;
              addMessageToChat(newChatMessage);
              isWaitingForGeneration = false;
            }
            clearInterval(intervalId);
            refArray[refIndex].current = data_array.slice(0, -1).join(" ");
            setCurrentIndex(currentIndex + 1);
            setMessagesCounter(messagesCounter + 1);
            // setTalkingState("idle");
            setHasRan(true);
            isAudioFinished = true;
            // Set final state after generation is complete
            setIsTyping(false);

                  //////// code generation ///////
          const generatedResponse = await generateCodeOnly(
            message,
            setGenerateForCode,
            chatHistoryForCodeGen
          );
          // Append the new prompt and response to the existing history
          setChatHistoryForCodeGen((prevHistory) =>
            prevHistory
              ? `${prevHistory}\nUser: ${message}\nAssistant: ${generatedResponse}`
              : `User: ${message}\nAssistant: ${generatedResponse}`
          );

          setAppState(AppState.CODEGEN_READY);
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
        }
      }, 1000);

    } catch (error) {
      console.error("Error generating code:", error);
      isWaitingForGeneration = false;
      setIsTyping(false); // Ensure to stop typing indicator on error
      setAppState(AppState.INITIAL); // Reset to initial state on error
    }
  };

  const intervalRef = useRef(null);
  const [isError, setIsErrored] = useState(false);



  const sayHello = async (isReset) => {

    // toast.error(selectedLanguageOption['value'].toString());

    isWaitingForGeneration = true;
    setIsErrored(false);

    setShowHello(false);
    setIsTyping(true);

    if (isStreaming[refIndex]) {
      toast.error("Wait for generation to finish before starting a new one");
      return;
    }

    if (hasRan) {
      refIndex += 1;
    }

    if (!handleVoiceClassification(isVoiceClassified, setIsTyping)) {
      return;
    }

    const newChatHistory = [];
    setChatHistory(newChatHistory as []);

    // setTalkingState("thinking");
    setStringToShow("");
    setCurrentIndexTextStream(0);
    setTextStream([]);
    setLongGenerationWaiting(true);

    const conversationHistory = [];

    var rag_intro = "";
    var normal_intro = "";

    if (hasUserChatted) {
      rag_intro = `Greet the user by saying something along the lines of 'Hello ${userData.Name}', and briefly introduce yourself to the user and let them know what context your have. Keep your greeting very short please.`;
      normal_intro = `Welcome the user back by saying something along the lines of "Welcome back ${userData.Name}". say it in a way that you're happy to see a good old friend, and to kickstart a conversation`
    }
    else {
      rag_intro = `Greet the user by saying something along the lines of 'Hello ${userData.Name}', and briefly introduce yourself to the user and let them know what context your have. Keep your greeting very short please.`;
      normal_intro = `Greet the user by saying something along the lines of 'Hello ${userData.Name}', and briefly and nicely introduce yourself to the user in a nice way lto make the user familiar with you and want to engage more with you`;
    }
    const openAIChatHistory2 = [
      ...conversationHistory,
      {
        text: chatbotData?.rag ? rag_intro : normal_intro,
        sender: "user",
        profile: "/images/profile.png",
        audioUrl: "",
      },
    ];

    let isAdded = false;

    const onErrorInStream = (error) => {
      try {
        console.trace();
        setLoading(false);
        setTalkingState("idle");
        // setIsTyping(false);
        setIsErrored(true);
        isWaitingForGeneration = false;

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
        
    
        if (error) {
          console.error("Error in stream:", error);
          // toast.error(`Error: ${error.message || "An error occurred"}`);
        } else {
          console.error("Unknown error in stream");
          // toast.error("Unknown error occurred during audio playback or streaming.");
        }
    
      } catch (innerError) {
        console.error("Error handling failed:", innerError);
        // toast.error("An error occurred while handling another error.");
      }
    };

    try {
      const uuid = uuidv4();
        // Step 2: Get the current time
      const now = new Date();
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      // Step 3: Concatenate UUID and time
      const myUUID = `${uuid}-${minutes}${seconds}`;
      let convertedMessages = chatbotData?.rag
        ? convertRAGMessages(openAIChatHistory2, inputPrompt, userData.Name, refArray)
        : convertMessagesShort(openAIChatHistory2, inputPrompt, userData.Name, refArray);

      const isUserOnIphone = isIphone();
      const searchParams = prepareAudioSearchParams(myUUID, convertedMessages, inputPrompt, chatbotData, selectedVoice, isUserOnIphone, selectedModelOption['label'], false, selectedLanguageOption.value, isMuted);



      if (isUserOnIphone) {
        if (audioContainerRef.current) {
          const src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
          // Remove existing audio element if any
          audioContainerRef.current.innerHTML = '';

          // Create a new audio element
          const audioElement = document.createElement('audio');
          audioElement.className = 'w-full';
          audioElement.controls = true;
          audioElement.autoplay = true;

          // Create a source element
          const sourceElement = document.createElement('source');
          sourceElement.src = src;
          sourceElement.type = 'audio/mpeg';

          // Append the source to the audio element
          audioElement.appendChild(sourceElement);

          // Append the audio element to the container
          audioContainerRef.current.appendChild(audioElement);

          // Load and play the audio
          audioElement.load();
          // Add canplay event listener to play the audio when it is ready
          audioElement.addEventListener('ended', () => {
            // audioContainerRef.current.innerHTML = '';
          });

          audioElement.addEventListener('loadeddata', () => {
          
            // audioElement.src = '';
            // setAudioSrc(null);
            // toast.error("Audio loadeddata");

            audioElement.play();


            // audioContainerRef.current.innerHTML = '';
          });

          audioElement.addEventListener('canplay', () => {
            // toast.error("Audio canplay");
            // audioElement.src = '';
            // setAudioSrc(null);
            // toast.error("Audio canplay");
            audioElement.play();
          });

          audioElement.addEventListener('progress', () => {
            // audioElement.src = '';
            // setAudioSrc(null);
            // toast.error("Audio onprogress");
          });

          audioElement.addEventListener('loadedmetadata', () => {
            // audioElement.src = '';
            // setAudioSrc(null);
            // toast.error("Audio loadedmetadata");
            audioElement.play();
            
          });

          audioElement.addEventListener('play', () => {
            // toast.error("Audio is playing");
            audioElement.play();
          });
        }
      } else {
        const url = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
        // console.log(url);
        playAudioOnNonIphone(url, audioRef, onErrorInStream, setTalkingState, undefined, true, isMuted );
      }

      setLoading(true);
      // setTalkingState("thinking");

      const newChatMessage: ChatMessage = {
        text: refIndex.toString(),
        sender: "system",
        profile: chatbotImage,
        audioUrl: "",
        time: formatTime(new Date()),
      };


      
      setIsStreamingForIndex(refIndex, true);

      const startInterval = () => {
        intervalRef.current = setInterval(async () => {
          try {
            const response = await fetch(
              `${RUNPOD_ROMANIAN_SERVER}/get/${myUUID}`,
              {
                method: "GET",
                headers: {
                  Accept: "application/json",
                },
              }
            );
            if (!response.ok) {
              throw new Error(`Error fetching data: ${response.statusText}`);
            }
            const data = await response.json();
    
            const data_array = data["array_data"];
            const totalSentences = findEndNumber(data_array);
            const newTextStream = data_array.join(" ");
    
            if (newTextStream.length > 1) {
              setIsTyping(false);
            }
    
            // if (newTextStream.length > 50) {
            //   isWaitingForGeneration = false;
            // }
    
            resultRef.current = data_array.join(" ");
            refArray[refIndex].current = data_array.join(" ");
    
            setTextStream(data_array.join(" ").replace("Treho", "Trejo"));
    
            if (totalSentences != null) {
              if (!isAdded) {
                isAdded = true;
                setIsTyping(false);
                addMessageToChat(newChatMessage);
                isWaitingForGeneration = false;
              }
              clearInterval(intervalRef.current); // Clear interval
              intervalRef.current = null;
              refArray[refIndex].current = data_array
                .slice(0, -1)
                .join(" ")
                .replace("Treho", "Trejo");
              if (screenSize > 575) {
                setTalkingState("idle");
              }
              setHasRan(true);
              isAudioFinished = true;
            }
          } catch (error) {
            console.error("Failed to fetch data:", error);
            toast.error("Failed to fetch data, please try to send another message");
            isWaitingForGeneration = false;
            setIsTyping(false);
            setIsLoading(false);
            clearInterval(intervalRef.current); // Clear interval on error
            intervalRef.current = null;
            setHasRan(true);
            isAudioFinished = true;
            // removeLastMessageFromChat();
          }
        }, 1000);
      };

      startInterval();

    } catch (error) {
      onErrorInStream(error);
      isWaitingForGeneration = false;
    }
  };


  const [selectedImage, setSelectedImage] = useState(null); // State to track the selected image for the modal
  const [showTokenizeForm, setShowTokenizeForm] = useState(false);

  const openModal = (image) => {
    setSelectedImage(image); // Set the selected image when user clicks on it
  };

  const closeModalimg = () => {
    setSelectedImage(null); // Close the modal by resetting selected image
  };

  const handleSelectImageForTokenize = (image: string) => {
    if (selectedImages?.includes(image)) {
      const images = [...selectedImages];
      const index = images.indexOf(image);
      images.splice(index, 1); // 2nd parameter means remove one item only
      setSelectedImages(images);
    } else {
      const images = [...selectedImages, image];
      setSelectedImages(images);
    }
  };

  const handleSelectAllImagesForTokenize = (images: string[] | undefined) => {
    setSelectedImages(images);
  };
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleButtonClick = () => {
    setPopupVisible(true);
    setTimeout(() => {
      setPopupVisible(false);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  const [isChatLoading, setIsChatLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsChatLoading(false);
    }, 4000);

    // Clear the timeout when the component unmounts or when isChatLoading changes to false
    return () => clearTimeout(timer);
  }, []);

  const [loadingmain, setLoadingmain] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingmain(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // const audiomain = () => {
  //   console.log("Voice to voice activated");

  //   if (!localStorage.getItem("oauthToken")) {
  //     dispatch(showPopUpAction(true));
  //     return;
  //   }

  //   setUserHangUp(false);
  //   setIsListening(true);

  //   setIsRecord1(true);
  //   setIsRecord2(false);

  //   const audio = new Audio(tone);
  //   audio.volume = 0.01;
  //   audio.play();
  //   startDictation();
  // };



  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);

  const handleLike = () => {
    if (isDisliked) {
      setIsDisliked(false);
    }
    setIsLiked(!isLiked);
  };

  const handleDislike = () => {
    if (isLiked) {
      setIsLiked(false);
    }
    setIsDisliked(!isDisliked);
  };

  // const handleHangup = () => {
  //   isWaitingForGeneration = false;
  //   if(isListening) {
  //     audioRef.current.pause();
  //     audioRef.current.currentTime = 0;

  //     onClickChatScreenAiAgentImg(0);
  //     setUserHangUp(true);

  //     setIsRecord1(false);
  //     setIsRecord2(false);

  //     setIsListening(false);
  //     // full_message = null;
  //   }

  //   onClickChatScreenAiAgentImg(0);

  // };

  const [isReset, setIsReset] = useState(false)

  const resetChat = () => {
    setChatHistory([]);
    setIsReset(true)
    refIndex = 0
    setIsStreaming(Array.from({ length: numberOfRefs }, () => false));
    refArray = Array.from({ length: numberOfRefs }, () => useRef());
    setIsTyping(false)
    setIsTypingComplete(false)
    // sayHello(true);
    finalStat(statsDataRef.current).then(() => {
      InitConversationsHistory(chatbotData);
      setStatsData({
        sent_messages_audio: 0,
        chat_id: statsData.chat_id,
        creator_user_id: statsData.creator_user_id,
        user_id: statsData.user_id,
        session_id: 0,
        conversation_id: 0,
        first: true,
        last_message_sender: "",
        last_message_chat: "",
        delete_stat: true,
      });
      sendingStat(statsData);
    });
  };

  const handleCopy = () => {
    copy(generateForCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  // const [volume, setVolume] = useState(0);
  const [animationSize, setAnimationSize] = useState('calc(100% + 0px)');

  // const baseRadius = 1104.48;
  // const addedRadius = (volume / 255) * 700; // Adding up to 500px based on volume
  // const totalRadius = baseRadius + addedRadius; // Total radius with dynamic volume addition

  const animationRef = useRef<HTMLDivElement>(null); // Refs to wave divs

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaElementSource(audioElement);
      source.connect(analyser);
      analyser.connect(audioContext.destination);
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      let animationFrameId;

      const updateWaveAnimation = () => {
        analyser.getByteFrequencyData(dataArray);
        const averageFrequency = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
        const sizeIncrement = averageFrequency / 10; // Adjust divisor for sensitivity

        // console.log({ sizeIncrement })
        const newSize = `calc(100% + ${sizeIncrement * 35}px)`;

        // Directly update the style of the animation div
        if (animationRef.current) {
          animationRef.current.style.width = newSize;
          animationRef.current.style.height = newSize;
        }

        // Schedule the next update
        animationFrameId = requestAnimationFrame(updateWaveAnimation);
      };

      // Start the animation loop when the audio is playing
      const handlePlay = () => {
        audioContext.resume().then(() => {
          updateWaveAnimation(); // Start the animation loop
        });
      };

      // const handlePause = () => {
      //   // Stop updating but do not suspend the audio context
      //   if (animationFrameId) {
      //     cancelAnimationFrame(animationFrameId);
      //   }
      // };

      const handleEnded = () => {
        console.log("EnDED")
        // Stop updating the animation when audio ends
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
        if (animationRef.current) {
          animationRef.current.style.width = '100%'; // Reset size
          animationRef.current.style.height = '100%'; // Reset size
        }
      };

      audioElement.addEventListener("play", handlePlay);
      // audioElement.addEventListener("pause", handlePause);
      audioElement.addEventListener("ended", handleEnded); // Listen for the end of the audio

      // Cleanup event listeners and close audio context on unmount
      return () => {
        audioElement.removeEventListener("play", handlePlay);
        // audioElement.removeEventListener("pause", handlePause);
        audioElement.removeEventListener("ended", handleEnded);
        audioContext.close();
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId); // Cancel any pending animation frames
        }
      };
    }
  }, [audioRef]);

  return (
    <>
      <div className="MainContent page-bg chat-bg">
        <div className="Breadcrumb-Wrap pb-0 ChatScreenHeaderSpace">
          <ChatScreenHeader
            setOpenShare={setOpenShare}
            resetChat={resetChat}
            onClickChatScreenAiAgentImg={onClickChatScreenAiAgentImg}
            isChatScreenAiAgentImgClicked={isChatScreenAiAgentImgClicked}
            chatbotData={chatbotData}
            setIsDropdownOpen={setIsDropdownOpen}
            setOpenSetting={setOpenSetting}
            selectedModelOption={selectedModelOption}
            setSelectedModelOption={setSelectedModelOption}
            modelOptions={modelOptions}
            selectedLanguageOption={selectedLanguageOption}
            setSelectedLanguageOption={setSelectedLanguageOption}
            isResetDisable={isStreaming[refIndex]}
          />
        </div>
        <div
  style={{
    opacity: 0,
    width: '40px',
    height: '40px',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 9999,
    pointerEvents: 'none'
  }}
>

</div>

        <div
          className={classNames("ChatScreenWrap", {
            ChatScreenWrapShow: isChatScreenAiAgentImgClicked === 0,
            ChatScreenCodeShow: isChatScreenAiAgentImgClicked === 2,
            ChatScreenLive: isChatScreenAiAgentImgClicked === 3,
            ChatHistory: isChatScreenAiAgentImgClicked === 4,
            DropdownFade: isDropdownOpen,
          })}
        >
          <div className="ChatScreenMain cpx-2">
          {/* <div
            className={`soundwaveeUser ${isListening && talkingState !== 'talking' ? 'show' : 'hide'}`}
            style={{
              background: `radial-gradient(${totalRadius}px at 50% calc(-100px + 100vh), rgb(6 135 241 / 77%) 0%, rgb(23 114 180 / 2%) 30%, rgba(24, 24, 27, 0) 100%)`,
            }}
          ></div> */}
            <div className="ChatScreenBodyWrap">
              <div
                className={`FCompanions-Main FCompanionslive live-active ${
                  activeTab === "live" ? "live-active" : ""
                }`}
              >
                {activeTab === "static" ? (
                  <div className="FCompanions-Img">
                    <Image
                      className="FCompanions-img-div"
                      src={chatbotData?.img}
                    />
                    {/* <div className="timeandicons d-flex d-none">
                      <div className="microphoneandpauseicon">
                        <Link
                          to="javascript:void(0)"
                          className={
                            isChatScreenAiAgentImgClicked === 1
                              ? "active btn btn-danger"
                              : "closeicon btncallcut"
                          }
                          onClick={() => handleHangup()}
                        >
                          <lord-icon
                            src="https://cdn.lordicon.com/nxjpnjjf.json"
                            color="white"
                          ></lord-icon>
                        </Link>
                      </div>
                      <div className="microphoneandpauseicon mr-0">
                        <Link
                          to="javascript:void(0)"
                          onClick={audiomain}
                          className="btncallreceive btn btn-success"
                        >
                        <Link className="btncallreceive btn btn-success" style={{backgroundColor: '#198754', transform:'rotate(-145deg)', color:'#fff'}} onClick={audiomain}>
                        <lord-icon
                              src="https://cdn.lordicon.com/nxjpnjjf.json"
                              trigger="loop"
                              color="white"
                            ></lord-icon>
                          </Link>
                      </div>
                    </div> */}

                    <div className="AudioSettingShow">
                      {talkingState === "idle" ? (
                        <p>
                          {/* <iframe src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json" className="soundwave"></iframe> */}
                          {/* <div className='soundwavee'><span></span></div> */}
                        </p>
                      ) : (
                        <div>
                          {/* content to render when talkingState is not idle */}
                        </div>
                      )}
                      {talkingState === "talking" ? (
                        <>
                          {/* <div className='soundwavee'></div> */}
                          <div
                            ref={animationRef} // Attach the ref here
                            className="sound-wave-animation"
                            style={{
                              // Set initial styles for your animation div
                              width: '100%',
                              height: '100%',
                              transition: 'width 0.1s ease, height 0.1s ease', // Smooth transitions
                            }}
                          ></div>
                          {/* <div className="sound-wave-container">
                            <div className="sound-wave">
                              <div className="wave" ref={(el) => (animationRefs.current[0] = el!)}></div>
                              <div className="wave" ref={(el) => (animationRefs.current[1] = el!)}></div>
                              <div className="wave" ref={(el) => (animationRefs.current[2] = el!)}></div>
                            </div>
                          </div> */}
                          {/* <iframe
                            src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json"
                            className="soundwave"
                          ></iframe> */}
                        </>
                      ) : (
                        <div>
                          {/* content to render when talkingState is not idle */}
                        </div>
                      )}
                      {talkingState === "listening" ? (
                        <p></p>
                      ) : (
                        <div>
                          {/* content to render when talkingState is not idle */}
                        </div>
                      )}
                      {talkingState === "thinking" ? (
                        <p></p>
                      ) : (
                        <div>
                          {/* content to render when talkingState is not idle */}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="FCompanions-Img" ref={imageRef}>
                    <Image
                      className="FCompanions-img-div"
                      style={{ borderRadius: "15px" }}
                      src={chatbotData?.img}
                    />
                    <div className="AudioSettingShow">
                      {talkingState === "idle" ? (
                        <p></p>
                      ) : (
                        <div>
                          {/* content to render when talkingState is not idle */}
                        </div>
                      )}
                      {talkingState === "talking" ? (
                        <p>
                          <iframe
                            src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json"
                            className="soundwave"
                          ></iframe>
                        </p>
                      ) : (
                        <div>
                          {/* content to render when talkingState is not idle */}
                        </div>
                      )}
                      {talkingState === "listening" ? (
                        <p></p>
                      ) : (
                        <div>
                          {/* content to render when talkingState is not idle */}
                        </div>
                      )}
                      {talkingState === "thinking" ? (
                        <p>
                          {/* <div className="typing">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                        <iframe src="https://lottie.host/embed/fdd106b2-2576-4038-b826-ed75ec58898d/iFyVZq89dK.json" className="soundwave"></iframe> */}
                        </p>
                      ) : (
                        <div>
                          {/* content to render when talkingState is not idle */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="FCompanions-Info">
                  <div className="FCompanions-tab d-flex">
                    <ul className="FCompanions-view-setting">
                      <div ref={audioContainerRef}></div>
                      <li>
                        <Button
                          variant=""
                          // to="javascript:void(0)"
                          className={activeTab === "static" ? "active" : ""}
                          onClick={() => handleTabClick("static")}
                        >
                          {t("static")}
                        </Button>
                      </li>
                      <li>
                        <Button
                          variant=""
                          // to="javascript:void(0)"
                          className={activeTab === "live" ? "active" : ""}
                          onClick={() => handleTabClick("live")}
                        >
                          <span></span>
                          <Trans
                            i18nKey="live"
                            components={{
                              s: <span />,
                            }}
                          />
                        </Button>
                      </li>
                    </ul>
                    {/* <Button variant="" className="livechatmain btn btn-primary" onClick={handleShow}>
                  Call
                </Button> */}
                  </div>
                  <FCompanionsInfoLessSetting
                    chatbotData={chatbotData}
                    chatbotHistory={conversationData}
                    setStatsData={setStatsData}
                    setChatHistory={setChatHistory}
                    setMessagesCounter={setMessagesCounter}
                    description={summarizedDescription}
                  />
                </div>
              </div>
              {
                <HistoryDisplay
                  history={appHistory}
                  currentVersion={currentVersion}
                  revertToVersion={(index) => {
                    if (
                      index < 0 ||
                      index >= appHistory.length ||
                      !appHistory[index]
                    )
                      return;
                    setCurrentVersion(index);
                    setGeneratedCode(appHistory[index].code);
                  }}
                  shouldDisableReverts={appState === AppState.CODING}
                  activeAppState={appState}
                  reset={reset}
                  setImageFilesWithPreviews={setImageFilesWithPreviews}
                />
              }
              <div ref={chatFieldRef} className="ImageUploadPreviewChat">
                {chatHistory.map((msg, i) => (
                  <div key={i} className="chatmain">
                    {" "}
                    {/* It's important to provide a unique key for each child in a list */}
                    {msg.sender === "user" ? (
                      <div className="ChatScreenMsg ChatScreenMsgLeft">
                        <div className="ChatScreenMsgBox">
                        {
                              msg.imageUrl ? (
                                <>
                                  {(appState === AppState.CODING || appState === AppState.CODE_READY) ? (
                                    <>
                                      <div className="imgborder">

                                          <Image
                                            src={msg.imageUrl}
                                            style={{ maxWidth: "100%", height: "auto" }}
                                          />


                                        {appState === AppState.CODING ? (
                                          <div className="ocrloader">
                                            <em></em>
                                            <span></span>
                                          </div>
                                        ) : null}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="imgborder">
                                      <Image
                                        src={msg.imageUrl}
                                        style={{ maxWidth: "100%", height: "auto" }}
                                      />
                                    </div>
                                  )}
                                </>
                              ) : null
                            }


                           <p>{msg.text}</p>
                        </div>

                        <small>{msg.time}</small>
                      </div>
                    ) : (
                      <div className="ChatScreenMsg ChatScreenMsgRight">
                        {isStreaming[parseInt(msg.text)] ? null : (
                          <div className="ChatScreenMsgWrap">
                            <i>
                              <img
                                src={chatbotData?.img}
                                alt=""
                                onClick={() => openModal(chatbotData?.img)}
                              />
                            </i>

                            <div className="ChatScreenMsgWrapBox">
                              {/* <div  style={{display:'inline'}}> */}
                              <h6 style={{ display: "inline" }}>
                                {t(chatbotData.name)}
                              </h6>
                              <button
                                onClick={toggleMute}
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  color: "#adb5bd",
                                }}
                              >
                                {isMuted ? (
                                  <Icon icon="iconoir:sound-off-solid" color="red" />
                                ) : (
                                  <Icon icon="iconoir:sound-low-solid" />
                                )}
                              </button>
                              {/* </div> */}

                              {msg.images && (
                                <>
                                  <div
                                    className="chatscreenmsgimgs"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                    }}
                                  >
                                    <Row>
                                      {msg.images.map((image, index) => (
                                        <Col lg={6} key={index}>
                                          <div className="chatscreenmainimg chatscreenmainimg2">
                                            <label className="checkbox style-d">
                                              <Link
                                                className="eyeicon"
                                                to="javascript:void(0)"
                                                onClick={() => openModal(image)}
                                              >
                                                <Icon icon="ion:eye-sharp" />
                                              </Link>
                                              <input
                                                type="checkbox"
                                                name="checkimg"
                                                id="checkimg"
                                                onChange={() =>
                                                  handleSelectImageForTokenize(
                                                    image
                                                  )
                                                }
                                                checked={selectedImages?.includes(
                                                  image
                                                )}
                                              />
                                              <div className="checkbox__checkmark"></div>
                                              <div
                                                onClick={() => openModal(image)}
                                                style={{ zIndex: "9" }}
                                              >
                                                <img
                                                  src={image}
                                                  alt={`Generated ${index}`}
                                                />
                                                {/* <h6><i><Image src="" /></i> </h6> */}
                                              </div>
                                              <Button
                                                className="btn-primary"
                                                disabled={
                                                  !selectedImages?.includes(
                                                    image
                                                  )
                                                }
                                              >
                                                Tokenize
                                              </Button>
                                            </label>
                                          </div>
                                        </Col>
                                      ))}
                                    </Row>
                                  </div>
                                  <div className="btnImgwrap">
                                    <span>
                                      <Image src="/assets/images/btn-left-after.svg" />
                                    </span>
                                    {msg.images.length ===
                                    selectedImages?.length ? (
                                      <Button
                                        className="btn-primary"
                                        onClick={() => {
                                          setShowTokenizeForm(true);
                                          handleShow();
                                        }}
                                      >
                                        Tokenized all
                                      </Button>
                                    ) : (
                                      <Button
                                        className="btn-primary"
                                        onClick={() =>
                                          handleSelectAllImagesForTokenize(
                                            msg.images
                                          )
                                        }
                                      >
                                        Select all
                                      </Button>
                                    )}
                                    <span>
                                      <Image src="/assets/images/btn-right-after.svg" />
                                    </span>
                                  </div>
                                </>
                              )}

                              {isNaN(parseInt(msg.text)) ? (
                                <>
                                {msg.text && <div className="ChatScreenMsgBox">
                                  <p>{msg.text.replace("++","").replace("--","").replace(/\[END\]\d/g, "")}</p>
                                </div>}
                                </>
                              ) : refArray[parseInt(msg.text)] &&
                                refArray[parseInt(msg.text)].current != null ? (
                                <div className="ChatScreenMsgBox">
                                  {/* {console.log({ text: parse(
                                        refArray[parseInt(msg.text)].current.replace("++","").replace("--","").replace(/\[END\]\d/g, "")
                                      )})} */}
                                  {isStreaming[parseInt(msg.text)] ? null : (
                                    <p>
                                      {parse(
                                        refArray[parseInt(msg.text)].current.replace("++","").replace("--","").replace(/\[END\]\d/g, "")
                                      ) ?? "error"}
                                    </p>
                                  )}

                                    <SourceCards sources={msg.ragSources} />
                                   {/* {isTypingComplete && (
                                    <SourceCards sources={msg.ragSources} />
                                  )} */}
                                  {msg.images && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      {msg.images.map((status, index) => (
                                        <div
                                          key={index}
                                          onClick={() =>
                                            openModal(msg.images[index])
                                          }
                                        >
                                          <Trans i18nKey="key" /> {index}
                                          <img
                                            src={msg.images[index]}
                                            alt={`Generated ${index}`}
                                            style={{
                                              width: "125px",
                                              height: "125px",
                                              margin: "5px",
                                              borderRadius: "5%",
                                            }}
                                          />
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ) : null}
                              <div className="timeandicons d-flex justify-content-end">
                                {/* {console.log({ data :  refArray[parseInt(msg.text)].current})} */}
                                {msg?.images?.length > 0 ? '' : <ul className="chaticons">
                                  <li>
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={handleLike}
                                    >
                                      <Icon
                                        icon={
                                          isLiked
                                            ? "iconamoon:like-fill"
                                            : "iconamoon:like-thin"
                                        }
                                      />
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={handleDislike}
                                    >
                                      <Icon
                                        icon={
                                          isDisliked
                                            ? "iconamoon:dislike-fill"
                                            : "iconamoon:dislike-thin"
                                        }
                                      />
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          parse(
                                            refArray[parseInt(msg.text)]?.current.replace("++","").replace("--","").replace(/\[END\]\d/g, "")
                                          )
                                        );
                                        toast.success("Text copied!");
                                      }}
                                    >
                                      <Icon icon="iconoir:copy" />
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <Link className='sharemobileicon' to="#" onClick={() => setOpenShare(true)}><Icon icon="basil:share-outline" /></Link>
                                  </li> */}
                                </ul>}

                                <small>{msg.time}</small>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                {longGenerationWaiting &&
                isTyping == false &&
                isTypingComplete == false ? (
                  <div>
                    <div className="ChatScreenMsg ChatScreenMsgRight">
                      <div className="ChatScreenMsgWrap">
                        <i>
                          <img
                            src={chatbotData?.img}
                            alt=""
                            onClick={() => openModal(chatbotData?.img)}
                          />
                        </i>

                        <div className="ChatScreenMsgWrapBox">
                          <h6 style={{ display: "inline" }}>
                            {t(chatbotData.name)}
                          </h6>
                          <button
                            onClick={toggleMute}
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                              color: "#adb5bd",
                            }}
                          >
                            {isMuted ? (
                              <Icon icon="iconoir:sound-off-solid" color="red" />
                            ) : (
                              <Icon icon="iconoir:sound-low-solid" />
                            )}
                          </button>
                          <div className="ChatScreenMsgBox">
                            <Typewriter
                              text={textStream.replace("++","").replace("--","")}
                              speed={isMuted ? 10 : 100}
                              onComplete={handleTypingComplete}
                            />


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {isTyping && chatbotData?.img != undefined  && isError == false? (
                  <div style={{ "textAlign": "left" }}>
                    <i>
                      <img
                        src={chatbotData.img}
                        style={{
                          width: "50px",
                          "borderRadius": "40px",
                          "marginRight": "3px",
                        }}
                      />
                    </i>{" "}
                    <div style={{ display: "inline-block" }}>
                      <div className="ChatScreenMsgWrapBox">
                        <h6>{t(chatbotData.name)}</h6>
                        <img src={"/assets/loading.gif"}></img>
                      </div>{" "}
                    </div>
                  </div>
                ) : null}
                {isImageGenerating ? (
                  <div style={{ "textAlign": "left" }}>
                    <i>
                      <img
                        src={chatbotData.img}
                        style={{
                          width: "50px",
                          "borderRadius": "40px",
                          "marginRight": "3px",
                        }}
                      />
                    </i>{" "}
                    <div style={{ display: "inline-block" }}>
                      <div className="ChatScreenMsgWrapBox">
                        <h6>{t(chatbotData.name)}</h6>
                        <img src={"/assets/loading.gif"}></img>
                      </div>{" "}
                    </div>
                  </div>
                ) : null}

              </div>
            </div>
            <ChatScreenFooter
              message={message}
              setMessage={setMessage}
              resetChat={resetChat}
              streamTextGeneration={streamTextGeneration}
              imageFilesWithPreviews={imageFilesWithPreviews}
              setReferenceImages={setReferenceImages}
              removeImage={removeImage}
              setIsChatScreenAiAgentImgClicked={
                setIsChatScreenAiAgentImgClicked
              }
              doCreate={doCreate}
              doUpdate={doUpdate}
              appState={appState}
              setUpdateInstruction={setUpdateInstruction}
              startListening={startListening}
              isVoiceToVoice={isVoiceToVoice}
              isListening={isListening}
              isChatScreenAiAgentImgClicked={isChatScreenAiAgentImgClicked}
              chatbotData={chatbotData}
              talkingState={talkingState}
              mobileVoiceScreen={mobileVoiceScreen}
              setMobileVoiceScreen={setMobileVoiceScreen}
              generateImages={generateImages}
              updateMessageToSend={updateMessageToSend}
              setIsListening={setIsListening}
              setIsVoiceToVoice={setIsVoiceToVoice}
              userHangUp={userHangUp}
              codeGeneration={codeGeneration}
              setShowModal={setShowModal}
              toggleMute={toggleMute}
              isMuted={isMuted}
              // audiomain={audiomain}
              selectedLanguageMsCode={selectedLanguageOption?.msLangCode}
              // setVolume={setVolume}
              handleDictate={handleDictate}
              handleProgress={handleProgress}
            />
          </div>
          <div
            className={`FCompanions-Main FCompanionsChatScreen ${
              activeTab === "live" ? "live-active" : ""
            }`}
          >
            {loadingmain ? (
              <div className="FCompanions-Img skeleton">
                <SmoothImage
                  className="FCompanions-img-div opacity-0"
                  src={chatbotData?.img}
                  transitionTime={1.0}
                />
                <span className="unofficialtag opacity-0">Unofficial</span>
              </div>
            ) : (
              <div className="FCompanions-Img">
                <Image className="FCompanions-img-div" src={chatbotData?.img} />
                {chatbotData?.source === 4 ? (
                  <span className="officialtag">Official</span>
                ) : (
                  <span className="unofficialtag">Unofficial</span>
                )}
                <p className="hovertext">
                  This independently created AI offers a fan-based,
                  non-authoritative experience for educational entertainment. it
                  is NOT officially affiliated with, nor does it represent, the
                  actual character or real individual. The content, derived from
                  public information, is designed for interactive engagement,
                  teaching, and appreciation through parody and commentary
                </p>
              </div>
            )}

            <div className="FCompanions-Info">
              {loadingmain ? (
                <div className="skeleton FCompanions-tab d-flex">
                  <ul className="FCompanions-view-setting opacity-0">
                    <li>
                      <Button
                        variant=""
                        // to="javascript:void(0)"
                        className={activeTab === "static" ? "active" : ""}
                        onClick={() => handleTabClick("static")}
                      >
                        {t("static")}
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant=""
                        // to="javascript:void(0)"
                        className={activeTab === "live" ? "active" : ""}
                        // onClick={() => handleTabClick("live")}
                        onClick={handleButtonClick}
                      >
                        <span></span>
                        <Trans
                          i18nKey="live"
                          components={{
                            s: <span />,
                          }}
                        />
                      </Button>
                    </li>
                  </ul>

                  <div
                    className={`comingsoonpopup opacity-0 ${
                      isPopupVisible ? "show" : ""
                    }`}
                  >
                    <p>Comming soon</p>
                  </div>

                </div>
              ) : (
                <div className="FCompanions-tab d-flex">
                  <ul className="FCompanions-view-setting">
                    <li>
                      <Button
                        variant=""
                        // to="javascript:void(0)"
                        className={activeTab === "static" ? "active" : ""}
                        onClick={() => handleTabClick("static")}
                      >
                        {t("static")}
                      </Button>
                    </li>
                    <li>
                      <Button
                        variant=""
                        // to="javascript:void(0)"

                        onClick={() => onClickChatScreenAiAgentImg(3)}
                        className={
                          isChatScreenAiAgentImgClicked === 3 ? "active" : ""
                        }
                      >
                        <span></span>
                        <Trans
                          i18nKey="Live"
                          components={{
                            s: <span />,
                          }}
                        />
                      </Button>
                    </li>
                  </ul>

                  <div
                    className={`comingsoonpopup ${
                      isPopupVisible ? "show" : ""
                    }`}
                  >
                    <p>Comming soon</p>
                  </div>

                </div>
              )}
              <FCompanionsInfoLessSetting
                chatbotData={chatbotData}
                chatbotHistory={conversationData}
                setStatsData={setStatsData}
                setChatHistory={setChatHistory}
                setMessagesCounter={setMessagesCounter}
                description={summarizedDescription}
              />
            </div>
            <p>
              <audio
                className="w-full"
                ref={audioRef}
                src={audioSrc}
                controls
                style={{ display: "none" }}
              >
              </audio>
            </p>
            <div className="primiumOrboxBox">
              <i>
                <Icon icon="solar:share-outline" />
              </i>
              <h5>{t("share_with_friends_and_earn_10_orbox")}</h5>
              <PrimaryBtn
                child={<>{t("start_sharing")}</>}
                className={"primiumOrbobtns"}
                linkName={"javascript:void(0)"}
                onClick={() => setOpenShare(true)}
              />
            </div>
          </div>
          <div
            className={`FCompanions-Main chathistorymain ${
              activeTab === "live" ? "live-active" : ""
            }`}
          >
            <div className="chathistorytitle d-flex">
              <h5>
                <Icon icon="iconamoon:history-light" /> {t("chat_history")}
              </h5>
              <Link
                to="javascript:void(0)"
                className={
                  isChatScreenAiAgentImgClicked === 1 ? "CloseBtn" : ""
                }
                onClick={() => setIsChatScreenAiAgentImgClicked(0)}
              >
                <Icon icon="solar:close-circle-linear" />
              </Link>
            </div>
            <div className="chats">
              <h6>Previous Conversations</h6>
              <ul>
                {Array.isArray(conversationData) &&
                  conversationData.map((item, index) => (
                    <li key={index}>
                      <Link
                        to=""
                        onClick={async (event) => {
                          event.preventDefault(); // Prevent the default link behavior
                          setStatsData((prev) => {
                            if (prev.sent_messages_audio > 0) {
                              finalStatHistory({
                                ...prev,
                                conversation_id: item.ConversationId,
                              });
                            }
                            return {
                              ...prev,
                              conversation_id: item.ConversationId,
                              sent_messages_audio: 0,
                            };
                          });
                          setMessagesCounter(0);

                          // AJAX call to S3 bucket (production url version)
                          const url = `https://orbofi-conversations.s3.ap-southeast-1.amazonaws.com/${item.ConversationId}.json`;
                          try {
                            const response = await axios.get(url);

                            const newChatHistory = response.data.flatMap(
                              (conver) => {
                                let images = [];
                                let text = conver.text;

                                try {
                                  const parsedText = JSON.parse(conver.text);
                                  if (Array.isArray(parsedText)) {
                                    images = parsedText;
                                    text = ""; // Clear text if it's an array of images
                                  }
                                } catch (e) {
                                  // If parsing fails, treat text as a normal string
                                }
                                if (images.length > 0)
                                {
                                  return {
                                    text: StripHtmlTags(text),
                                    sender: conver.sender,
                                    profile: "/images/profile.png",
                                    audioUrl: "",
                                    images: images,
                                  };
                                }
                                else{
                                  return {
                                    text: StripHtmlTags(text),
                                    sender: conver.sender,
                                    profile: "/images/profile.png",
                                    audioUrl: "",
                                  };
                                }


                              }
                            );

                            setChatHistory(newChatHistory);
                          } catch (error) {
                            console.error(
                              "Error fetching data from S3: ",
                              error
                            );
                          }
                        }}
                      >
                        <span>
                          <Image
                            className="FCompanions-img-div"
                            style={{ borderRadius: "15px" }}
                            src={chatbotData?.img}
                          />
                        </span>
                        {item.ChatHeader.replace("++","").replace("<p>", "").replace("</p>", "").replace("--","").replace("[\"https://storage.googleapis.com/factories_orbofi/", "Images")}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="ChatScreenCode">
            {appState === AppState.CODING ||
            appState === AppState.CODE_READY ? (
              <>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="c-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <span>
                          <Icon icon="ant-design:desktop-outlined" /> Desktop
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <span>
                          <Icon icon="clarity:mobile-solid" /> Mobile
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <span>
                          <Icon icon="ph:code" /> Code
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                    <Preview code={generatedCode} device="desktop" appState={appState} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Preview code={generatedCode} device="mobile" appState={appState} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <CodeTab
                        code={generatedCode}
                        setCode={setGeneratedCode}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </>
            ) : appState === AppState.CODEGEN_LOADING ||
              appState === AppState.CODEGEN_READY ? (
              <div
                style={{
                  maxHeight: "900px",
                  overflow: "auto",
                  padding: "1em",
                  background: "#2d2d2d",
                  borderRadius: "8px",
                }}
              >
                <button
                  onClick={handleCopy}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    padding: "5px 10px",
                    background: "#4caf50",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {copied ? "Copied!" : "Copy"}
                </button>
                <SyntaxHighlighter language="python" style={solarizedlight}>
                  {generateForCode}
                </SyntaxHighlighter>
              </div>
            ) : (
              <div className="codegeneration">
                <span></span>
                <Icon icon="pepicons-print:code-circle" />
                <p> your generations will appear here</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <PopupModal
        show={openShare}
        handleClose={setOpenShare}
        className="default-popup login-popup setting-popup"
        backdrop={undefined}
      >
        <Link
          to="javascript:void(0)"
          className="CloseBtn"
          onClick={() => setOpenShare(false)}
        >
          <Icon icon="solar:close-circle-linear" />
        </Link>
        <InviteFriendPopup />
      </PopupModal>

      {/* Modal for displaying the selected image */}
      <Modal
        show={!!selectedImage}
        centered
        onHide={closeModalimg}
        className="imageviewmodal default-popup login-popup"
      >
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Selected Image"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          <Link
            to="javascript:void(0)"
            className="CloseBtn"
            onClick={closeModalimg}
          >
            <Icon icon="solar:close-circle-linear" />
          </Link>
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="default-popup login-popup talkenmodal"
      >
        <Modal.Header className="default-modal-header">
          <Button variant="" onClick={handleClose}>
            <Icon icon="ion:close-circle-outline" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {" "}
          {showTokenizeForm && <TokenizeForm selectedImages={selectedImages} />}
        </Modal.Body>
      </Modal>
      <Modal
        show={showHelloModal}
        centered
        onHide={handleCloseHello}
        className="default-popup login-popup"
        // style={{maxWidth: '350px'}}
      >
        <Modal.Body className="custom-modal-body">
          <div className="FCompanions-Img helloimg" ref={imageRef}>
            {chatbotData?.img ? <Image
              className="FCompanions-img-div"
              src={chatbotData?.img}
            /> : <i></i>}
          </div>
          <h3>
            Ask and generate anything
          </h3>
          {chatbotData?.string_id === "dannytrejo" || chatbotData?.string_id === "brunoguimarães" ? (
            <p className='text-center mb-1'>This is a Licensed and Awesome AI agent, using {chatbotData?.string_id === "dannytrejo" ? "Danny Trejo's" : "Bruno Guimarães's"} real voice and personality.</p>) : ''
          }
          {isVoiceClassified == false ? (
        <p><lord-icon src="https://cdn.lordicon.com/cnoksqio.json" trigger="loop" state="loop-cycle" colors="primary:#ffffff" style={{transform: 'scale(1.5)'}}></lord-icon></p>
      ) : (<Button variant="primary" onClick={sayHello} className='SayHelloButton mb-0' style={{ fontSize: '18px', paddingLeft: '40px', paddingRight: '40px', marginTop:'10px' }}><lord-icon src="https://cdn.lordicon.com/wosztvsp.json" trigger="loop"></lord-icon></Button>)}

        </Modal.Body>
      </Modal>
      {showPreview && (
        <div className="modal profileview" onClick={togglePreview}>
          <div className="modal-content">
            <img src={chatbotData.img} alt="Chatbot Preview" />
          </div>
        </div>
      )}

      {localStorage.getItem("oauthToken") ? (
        <>
          <PopupModal
            show={showModal}
            handleClose={() => {setShowHello(false);setShowModal(false)}}
            className={`default-popup login-popup  ${
              !showPaymentScreen ? "PricingPopupMain" : "PricingPaymentPopup"
            }`}
            backdrop={undefined}
          >
            {!showPaymentScreen ? (
              <Link
                to="javascript:void(0)"
                className="CloseBtn"
                onClick={() => {setShowHello(false);setShowModal(false)}}
              >
                <Icon icon="solar:close-circle-linear" />
              </Link>
            ) : (
              ""
            )}
            {!showPaymentScreen ? (
              <PricingTabs
                isFaq={false}
                onPaymentToggle={onShowPayment}
                setCurrentProduct={setCurrentProduct}
                isPopup={true}
              />
            ) : (
              <PricingPopup
                onPaymentToggle={onHidePayment}
                product={currentProduct}
              />
            )}
          </PopupModal>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default ChatScreenPage;
