import { Icon } from "@iconify/react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import ImageUpload from "../../Components/AppGenerator/ImageUpload";
import { ImageFileWithPreview } from "./interface";
import { AppState } from "../../Components/AppGenerator/hooks/types";
import ChatScreenGenerateOptions from "./ChatScreenGenerateOptions";
import { RegisterOrboxUse } from "../../Utils/Utils";
import { ProductList, SubProduct } from "../../Utils/Constants";
import { DownloadData } from "../../Utils/UserUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  ChatbotData,
  CheckIfUserPurchasedPremiumBot,
  IsUserOnFreeTrialForPremiumBot,
  LogUse
} from '../../Utils/ChatbotUtils';
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import PrimaryBtn from "../../Components/CommonBtn/PrimaryBtn";
import { userDataSelector } from "../../Redux/Slices/userDataSlice";
import UserChatbot from "../../Models/UserChatbot";
import tone from "../././../../public/images/bip-sound.mp3";
import DictateButton from '../../Components/MicrosoftDictatation/DictateButton';
import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';

interface ChatScreenFooterProps {
  message: string;
  setMessage: (message: string) => void;
  streamTextGeneration: (val: boolean) => void;
  imageFilesWithPreviews: ImageFileWithPreview[];
  setReferenceImages: (file: File) => void;
  removeImage: (imageIndex: number) => void;
  setIsChatScreenAiAgentImgClicked: (imageIndex: number) => void;
  doCreate: (inputType: "text" | "image", textPrompt?: any) => void;
  doUpdate: (instructions: string) => void;
  appState: AppState;
  setUpdateInstruction: (instruction: string) => void;
  chatbotData: UserChatbot;
  talkingState: string;
  mobileVoiceScreen: boolean;
  resetChat: () => void
  setMobileVoiceScreen: (val: boolean) => void;
  isChatMainTextShow: boolean;
  setIsChatMainTextShow: (val: boolean) => void;
  isMuted: boolean;
  toggleMute: (val: boolean) => void;
  handleDictate: any;
  handleProgress: any;
}


const ChatScreenFooter: React.FC<ChatScreenFooterProps> = ({resetChat, isMuted, toggleMute, setShowModal, mobileVoiceScreen, setMobileVoiceScreen, message, setMessage, streamTextGeneration, setReferenceImages, imageFilesWithPreviews, removeImage, setIsChatScreenAiAgentImgClicked, doCreate, doUpdate, appState, setUpdateInstruction, talkingState, startListening, isVoiceToVoice, isListening, isChatScreenAiAgentImgClicked, chatbotData, generateImages, updateMessageToSend, setIsListening, setIsVoiceToVoice, codeGeneration, selectedLanguageMsCode }) => {

  const [activeOption, setActiveOption] = useState("ask_and_chat");
  const [showImagePreview, setShowImagePreview] = useState(true);
  const [canTalkWithPremiumBot, setCanTalkWithPremiumBot] = useState(false);
  const [openPremiumBotPopup, setOpenPremiumBotPopup] = useState(false);
  const [isRecord1, setIsRecord1] = useState(false);
  const [isRecord2, setIsRecord2] = useState(false);
  const [userHangUp, setUserHangUp] = useState(true);
  const [isTyped, setIsTyped] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const userData = useSelector(userDataSelector);
  const audioRef = useRef<HTMLAudioElement>(null);

  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const microphoneRef = useRef(null);
  const dataArrayRef = useRef(null);
  const soundWaveRef = useRef(null); // Ref for the sound wave div

  useEffect(() => {
    if(isListening) {
      // Request access to the user's microphone
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
          analyserRef.current = audioContextRef.current.createAnalyser();
          microphoneRef.current = audioContextRef.current.createMediaStreamSource(stream);
          microphoneRef.current.connect(analyserRef.current);

          analyserRef.current.fftSize = 512;
          const bufferLength = analyserRef.current.frequencyBinCount;
          dataArrayRef.current = new Uint8Array(bufferLength);

          analyzeAudio();
        })
        .catch((error) => {
          console.error("Error accessing microphone: ", error);
        });

      return () => {
        if (audioContextRef.current) {
          audioContextRef.current.close();
        }
      };
    }
  }, [isListening]);

  const analyzeAudio = () => {
    analyserRef.current.getByteFrequencyData(dataArrayRef.current);

    let sum = 0;
    for (let i = 0; i < dataArrayRef.current.length; i++) {
      sum += dataArrayRef.current[i];
    }
    const avgVolume = sum / dataArrayRef.current.length;
    const speakingThreshold = 20; // Adjust this threshold based on testing

    console.log({ avgVolume, speakingThreshold, isSpeaking })

    // Set `isSpeaking` state based on the threshold
    if (avgVolume > speakingThreshold && !isSpeaking) {
      setIsSpeaking(true);
    } else if (avgVolume <= speakingThreshold) {
      setIsSpeaking(false);
    }

    // Calculate total radius for sound wave effect based on avgVolume
    const totalRadius = 900 + Math.min(avgVolume * 3, 300);
    if (soundWaveRef.current) {
      soundWaveRef.current.style.background = `radial-gradient(${totalRadius}px at 50% calc(-100px + 100vh), rgb(6 135 241 / 77%) 0%, rgb(23 114 180 / 2%) 30%, rgba(24, 24, 27, 0) 100%)`;
    }

    // Continue analyzing audio
    requestAnimationFrame(analyzeAudio);
  };



  const handleTextareaChange = (event: { target: { value: any; }; }) => {
    setMessage(event.target.value);
  };
  const [dirty, setDirty] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (dirty) {
      DownloadData(dispatch);
      setDirty(false);
    }
  }, [dirty]);

  useEffect(() => {
    localStorage.removeItem("premiumChatbotReturn");
    localStorage.removeItem("premiumChatbotReturnName");
    // If we are on a premium bot check that the user can talk with it
    if (chatbotData?.source === 4) {
      CheckIfUserPurchasedPremiumBot(chatbotData.chatbot_id).then((response: boolean) => { setCanTalkWithPremiumBot(response); });
    }
  }, [chatbotData]);


  const handleSendClick = async () => {


    if (!localStorage.getItem("oauthToken")) {
      dispatch(showPopUpAction(true));
      return;
    }

    // if (userData?.Role != "admin") {
    try {
      if (chatbotData?.source === 4 && !canTalkWithPremiumBot) {
        const freeTrial = await IsUserOnFreeTrialForPremiumBot(chatbotData.chatbot_id);
        if (!freeTrial) {
          setOpenPremiumBotPopup(true);
          return;
        }
        else
        {
          await LogUse(chatbotData.chatbot_id);
        }
      }
    } catch (error) {
      console.error("Error checking free trial status: ", error);
    }
    // }


    if (isMuted && userData?.Orbox + userData?.PremiumOrbox < 1 || !isMuted && userData?.Orbox + userData?.PremiumOrbox < 20) {
      setShowModal(true);
      return;
    }

    let AUDIO_ORBOX_QUANTITY = 1;

    if(!isMuted)
    {
      AUDIO_ORBOX_QUANTITY = 20;
    }

    if (activeOption === "image_generation") {
      AUDIO_ORBOX_QUANTITY = AUDIO_ORBOX_QUANTITY + 3;
      await RegisterOrboxUse(ProductList.ChatBot, true, AUDIO_ORBOX_QUANTITY, SubProduct.ChatBot.AskAndChat).then(() => { setDirty(true); });
      streamTextGeneration(false);
    }
    if (activeOption === "ask_and_chat") {
      streamTextGeneration(false);
      await RegisterOrboxUse(ProductList.ChatBot, true, AUDIO_ORBOX_QUANTITY, SubProduct.ChatBot.AskAndChat).then(() => { setDirty(true); });

    }
    else if (activeOption === "website") {
      // Check the appState and call doCreate or doUpdate accordingly
      if (appState === AppState.INITIAL) {

        // new condition if message is provided that means
        // its text 2 app otherwise its image 2 app
        if (message) {
          await RegisterOrboxUse(ProductList.ChatBot, true, AUDIO_ORBOX_QUANTITY, SubProduct.ChatBot.Apps).then(() => { setDirty(true); });
          doCreate("text", message);
        } else {
          await RegisterOrboxUse(ProductList.ChatBot, true, AUDIO_ORBOX_QUANTITY, SubProduct.ChatBot.Apps).then(() => { setDirty(true); });
          doCreate("image");
        }

      }
      else {
        await RegisterOrboxUse(ProductList.ChatBot, true, AUDIO_ORBOX_QUANTITY, SubProduct.ChatBot.Apps).then(() => { setDirty(true); });
        doUpdate(message);
      }
      setShowImagePreview(false); // Hide the image preview
    } else if (activeOption === "code") {
      await RegisterOrboxUse(ProductList.ChatBot, true, AUDIO_ORBOX_QUANTITY, SubProduct.ChatBot.Code).then(() => { setDirty(true); });
      codeGeneration();
    }
    setMessage("");
    setShowImagePreview(false); // Hide the image preview
  };

  const handleOptionClick = (option: string) => {
    setActiveOption(option);
    if (option === "update") {
      // Check the appState before calling doUpdate
      if (appState !== AppState.INITIAL) {
        doUpdate(message);
      }
    }
  };

  const setReturnData = () => {
    localStorage.setItem("premiumChatbotReturnName", chatbotData?.string_id);
  };
  const handleClose = () => setMobileVoiceScreen(false);

  const dictateButtonRef = useRef(null);
  const composerRef = useRef();

  const startDictation = () => {
    if (dictateButtonRef.current) {
      // toast.error("yest");
      dictateButtonRef.current.setStarted(true);
    }
  };

  const stopDictation = () => {
    if (dictateButtonRef.current) {
      dictateButtonRef.current.setStarted(false);
    }
  };


  const [speechServices, setSpeechServices] = useState(null);

  useEffect(() => {
    const initializeSpeechServices = async () => {
      const services = await createPonyfill({
        credentials: {
          region: `${import.meta.env.VITE_AZURE_LOCATION}`,
          subscriptionKey: `${import.meta.env.VITE_AZURE_KEY}`,
        },
      });
      setSpeechServices(services);
    };

    initializeSpeechServices();
  }, []);

  const SpeechGrammarList = speechServices
  ? speechServices.SpeechGrammarList
  : null;
const SpeechRecognition = speechServices
  ? speechServices.SpeechRecognition
  : null;

  const audiomain = () => {
    if (!localStorage.getItem("oauthToken")) {
      dispatch(showPopUpAction(true));
      return;
    }

    if (chatbotData?.source === 4 && !canTalkWithPremiumBot) {
      setOpenPremiumBotPopup(true);
      return;
    }

    if (isMuted && userData?.Orbox + userData?.PremiumOrbox < 1 || !isMuted && userData?.Orbox + userData?.PremiumOrbox < 20) {
      setShowModal(true);
      return;
    }


    if (isListening) {
      setIsListening(false);
    } else {
      setIsListening(true);
    }
      setIsRecord2(true);
      setIsRecord1(false);
      // setIsUserHangUp(false);
      const audio = new Audio(tone);
      audio.volume = 0.02;
      setTimeout(() => {
        audio.play();
      }, 200);
      startDictation();
    // }
  };

  const handleDictate = ({ result }) => {

    console.log("dictating...");

    if (isRecord2 == true && userHangUp == true ) {
      try {
        updateMessageToSend(result.transcript);
        console.log({ result })

        setIsVoiceToVoice(true);
        setMessage(result.transcript);
        // setIsUserHangUp(true);
        // setIsListening(false);

        if(result.transcript === "Play."){
          setMessage("");
          return;
        }
        else {
          console.log('else calling')
          // setIsRecord2(false);
          setIsRecord1(false);
          // setIsListening(false);
          streamTextGeneration(true);
          setIsSpeaking(false)
        }

      } catch (error) {
        console.error("Error in handleDictate:", error);
      }
    }
  };

  const handleProgress = ({ results }) => {
    console.log('progressing');
    try {
      if (isListening == false || isRecord2 == false) {
        // setIsListening(false);
        return;
      }
      else if(isRecord2 == false && userHangUp == true){
        return;
      }
      else {
        if(results?.length > 0) {
          setIsListening(true);
          console.log({ results })
          const interimTranscript = results
          .map((result) => result.transcript)
          .join(" ");
          setMessage(interimTranscript);
        }
      }

    } catch (error) {
      console.error("Error in handleProgress:", error);
    }
  };


  const handleHangup = () => {
    // isWaitingForGeneration = false;
    if(isListening) {
      // audioRef.current.pause();
      // audioRef.current.currentTime = 0;

      setIsChatScreenAiAgentImgClicked(0);
      setUserHangUp(true);

      setIsRecord1(false);
      setIsRecord2(false);

      setIsListening(false);
      // full_message = null;
    }

    setIsChatScreenAiAgentImgClicked(0);

  };
  console.log({ isListening, isSpeaking })
  return (
    <div className="chatscreenmainoption">
    <div
      ref={soundWaveRef} // Attach the ref here
      className={`soundwaveeUser ${isListening && isSpeaking  ? 'show' : 'hide'}`}
    ></div>
      <div className="ChatScreenFooterWrap">
        <Form.Check className="custom-switch ChatScreenChat" type="switch" id="custom-switch" label="Audio" checked={!isMuted} onClick={toggleMute} />
        <Link to="javascript:void(0)" className='orboxChat MobileorboxChat mobile-reset-chat'><span onClick={resetChat}><Icon icon="ep:refresh" style={{color: "white"}} /></span></Link>
        <div className="ChatScreenFooterMainWrap">
          <div className='ChatScreenFooterMain'>
            <ImageUpload setReferenceImage={(file: File) => {
              setShowImagePreview(true);
              // Adjusted to match the expected prop name. Assuming ImageUpload is designed to handle a single file.
              setReferenceImages(file);
            }} />
            <div className='Chat-msg-textarea'>

              {imageFilesWithPreviews.length > 0 && <ul className="ChatImgUploadWrap">
                {showImagePreview && imageFilesWithPreviews.map((fileWithPreview, index) => (
                  <li key={index}>
                    <span><img src={fileWithPreview.dataUrl} alt="Preview" /></span>
                    <button type="button" onClick={() => removeImage(index)}>
                      <Icon icon="iconamoon:close" />
                    </button>
                  </li>
                ))
                }
              </ul>}
              {isListening ? (

                <Form.Control as="textarea" rows={1} placeholder="" value={message} onChange={handleTextareaChange} onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSendClick();
                  }
                }} />

              ) : (
                <Form.Control as="textarea" rows={1} placeholder={t("type_message")} value={message} onChange={(e) => {
                  handleTextareaChange(e);
                  if(e.target.value.length > 0) {
                    setIsTyped(true);
                  } else {
                    setIsTyped(false);
                  }
                }} onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSendClick();
                    setIsTyped(false);
                  }
                }} />
              )}
            </div>

            {isTyped ? <Button variant="" onClick={handleSendClick} className="Chat-msg-send-btn">
              <Icon icon="ph:paper-plane-right" />
            </Button>
            :
            <Link to="javascript:void(0)" className='Chat-msg-recorder' onClick={audiomain}>
              <Icon icon="ph:microphone" style={{ color: isListening ? "red" : "grey" }} />
            </Link>}
          </div>
          <div className="ChatscreenFooterCall">
            {isListening ? <div className="microphoneandpauseicon">
              <Link to="#" className={isChatScreenAiAgentImgClicked === 1 ? "active btn btn-danger" : "closeicon btncallcut" } onClick={() => handleHangup()}>
                <lord-icon src="https://cdn.lordicon.com/nxjpnjjf.json" color="white" ></lord-icon>
              </Link>
            </div>
            :
            <div className="microphoneandpauseicon mr-0">
              <Link to="#" className="btncallreceive btn btn-success" style={{backgroundColor: '#198754', transform:'rotate(-145deg)', color:'#fff'}} onClick={() => { setIsChatScreenAiAgentImgClicked(3); audiomain(); }}>
                <lord-icon src="https://cdn.lordicon.com/nxjpnjjf.json" trigger="loop" color="white" ></lord-icon>
              </Link>
            </div>}
          </div>
        </div>
        <div style={{ opacity: 0, width: '40px', height: '40px', position: 'absolute', top: 0, left: 0, zIndex: 9999, pointerEvents: 'none'}}>
        <DictateButton
          ref={dictateButtonRef}
          onDictate={handleDictate}
          onProgress={handleProgress}
          speechGrammarList={SpeechGrammarList}
          speechRecognition={SpeechRecognition}
          className="dictate-button"
          lang={selectedLanguageMsCode}
        >
          <lord-icon
            src="https://cdn.lordicon.com/nxjpnjjf.json"
            trigger="loop"
            color="white"
          ></lord-icon>
        </DictateButton>
      </div>



      </div>
      <ChatScreenGenerateOptions handleOptionClick={handleOptionClick} setIsChatScreenAiAgentImgClicked={setIsChatScreenAiAgentImgClicked} activeOption={activeOption} />

      {/*Premium Chatbot Modal*/}
      <Modal show={openPremiumBotPopup} backdrop="static" keyboard={false} onHide={() => { setOpenPremiumBotPopup(false); }} className="login-popup default-popup chatmodal" centered>
        <Modal.Header className="default-modal-header">
          <Button variant="" onClick={() => { setOpenPremiumBotPopup(false); }}><Icon icon="ion:close-circle-outline" /></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="UpgradeCreateMoreAIBg-Info chataimodal OpenPremiumBotPopup">
            <Image src={chatbotData?.img} />
            <i><Icon icon="heroicons:sparkles-solid" /></i>
            <h5>Get Your Premium AI assistant</h5>
            <ul className="DannyAIPopuplist">
              <li>
                <i><Icon icon="fluent:person-voice-24-filled" /></i>
                <p>One of the most powerful AI assistants, with Danny Trejo’s personality and voice</p>
              </li>
              <li>
                <i><Icon icon="fluent:premium-person-24-regular" /></i>
                <p>Get answers for anything and generate anything. Powered by Premium AI models, like GPT 4-o, and Claude 3.5</p>
              </li>
              <li>
                <i><Icon icon="ri:chat-voice-fill" /></i>
                <p>Voice-powered, and real-time AI generation and interaction with Danny Trejo AI</p>
              </li>
            </ul>
            <PrimaryBtn child={<><i className="mb-0"><lord-icon src="https://cdn.lordicon.com/colzhtfe.json" colors="primary:#ffffff,secondary:#ffffff" trigger="loop"></lord-icon></i>{t("upgrade_your_account")} </>} className={"BtnPremiumPopup"} linkName={"/pricing?premium=true"} onClick={setReturnData} />
          </div>
        </Modal.Body>
      </Modal>


      {mobileVoiceScreen && (
        <Modal show={mobileVoiceScreen} onHide={handleClose} centered size="lg" className='default-modal mobilechat'>

          <Modal.Body>
            <div className="mobilechatanimation">
              {talkingState === "idle" || talkingState === "listening" && (
                <>
                  <Form.Control as="textarea" rows={1} value={message} onChange={handleTextareaChange} onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSendClick();
                    }
                  }} />
                  <div className="chatmainanimation mb-5">
                    <lord-icon
                      src="https://cdn.lordicon.com/zrkkrrpl.json"
                      trigger="loop"
                      colors="primary:#ffffff,secondary:#ffffff"
                    >
                    </lord-icon>
                    <p>Speak Now ... !</p>

                  </div>
                  <iframe src="https://lottie.host/embed/99e73ea7-4faa-48b4-80c9-f1017274fb76/d2WoRxuI9P.json"></iframe>

                </>
              )}
              {talkingState === "thinking" && <div>
                <Image src="assets/images/thinking.gif"></Image>
              </div>}
              {talkingState === "talking" &&
                <>
                  <span className="chatbotimgmain">
                    <Image src={chatbotData?.img} />
                  </span>
                  <iframe src="https://lottie.host/embed/99e73ea7-4faa-48b4-80c9-f1017274fb76/d2WoRxuI9P.json"></iframe>
                  <p className="speaktext" >{message}</p>
                </>
              }
            </div>
          </Modal.Body>
          <Button variant="" onClick={handleClose} className="mobilechatclose"><Icon icon="uiw:close" /></Button>
        </Modal>
      )}
    </div>
  );
};

export default ChatScreenFooter;
